import React from "react";

const ContactForm = () => {
  return (
    <div className="container">
      <div className="contactFormMain">
        <h1>Formulaire de contact</h1>
        <form id="contactForm">
          <input id="firstname" type="text" placeholder="Votre prénom"></input>
          <input id="lastname" type="text" placeholder="Votre nom"></input>
          <input id="email" type="text" placeholder="Votre email"></input>
          <input
            id="subject"
            type="text"
            placeholder="Le sujet de votre message"
          ></input>
          <textarea
            id="message"
            placeholder="Tapez votre message ici..."
          ></textarea>
          <button>Envoyer le message</button>
        </form>
      </div>
    </div>
  );
};

export default ContactForm;
