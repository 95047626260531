import React from "react";

const AventCalendar = () => {
  return (
    <>
      <div className="wrapper">
        <div className="article-info">
          <div>
            <h1>25 idées de calendriers de l'Avent pour adultes à offrir</h1>
            <p>
              Chaque année, les enfants attendent avec impatience de recevoir
              leur calendrier de l'Avent. Depuis quelques années, les adultes
              peuvent également ressentir cette excitation car beaucoup de
              boutiques développent leur propre calendrier de l'Avent avec un
              condensé de leurs meilleurs produits à prix abordable. Le
              calendrier de l'Avent est devenu un produit tendance voire
              collector préparé dont la sortie est préparée en grandes pompes
              comme les vitrines des Galeries Lafayettes.
              <br />
              Voici une liste deA à Z des calendriers de l'Avent à offrir ou à
              s'offrir pour les fêtes de fin d'année 2023, pour que le compte à
              rebours jusqu'à Noël devienne un plaisir.
            </p>
            <p className="theme-credit">Publié le 4 novembre 2023</p>
            <img
              alt="Celendriers de l'Avent pour adultes"
              src="https://res.cloudinary.com/dug8eeke1/image/upload/v1699001400/MyTrendIdeas/th%C3%A8me-calendrier-avent-atgq8kkwzmcm3icnclck.png"
            ></img>
          </div>
        </div>
      </div>
      <div className="grey-back">
        <div className="wrapper">
          <div className="article-details">
            <div className="ideas">
              <div className="ideas-container">
                <div className="idea-container">
                  <img
                    alt="Calendrier de l'Avent ARMANI"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698937907/MyTrendIdeas/calendrier-avent-armani-l6dqmqyuxcmiq9n5a8ok.jpg"
                  />
                  <div className="right-description">
                    <h3>A comme ARMANI</h3>
                    <p>
                      Venez ouvrir les portes de ma maison ARMANI. La marque
                      vous offre les clés et vous invite à découvrir leurs
                      parfums et cosmétiques iconiques dans ce calendrier de
                      l'Avent.
                    </p>
                    <p>
                      Produits : miniatures de parfums, rouges à lèvres,
                      mascara, eyeliner, ombre à paupière
                    </p>
                    <p>
                      Prix : 313,45 € pour 24 produits (dont 12 en taille
                      réelle) à découvrir en 24 jours
                    </p>
                    <a
                      href="https://www.lookfantastic.fr/armani-advent-calendar/14869392.html"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>313,45 € | Acheter sur LOOKFANTASTIC</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Calendrier de l'Avent BIOTHERM"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698916606/MyTrendIdeas/calendrier-biotherm-ukliq9ecibbequ4v8t9x.webp"
                  />
                  <div className="right-description">
                    <h3>B comme BIOTHERM</h3>
                    <p>
                      Le calendrier de la marque BIOTHERM réunit tous les soins
                      emblématiques de la marque comme les gammes Aquasource ou
                      Biosource.
                    </p>
                    <p>
                      Produits : soin pour les yeux, nettoyant, Beurre de
                      lèvres, contour des yeux, crèmes, lait de douche, crème de
                      nuit, crème main, lotion, démaquillant, masque et serum
                    </p>
                    <p>
                      Prix : 119,00 € pour 24 produits à découvrir en 24 jours
                    </p>
                    <a
                      href="https://www.sephora.fr/p/calendrier-de-l-avent---coffret-soin-P10052831.html"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>109,00 € | Acheter sur SEPHORA </button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Calendrier de l'Avent Charlotte TILBURY"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698899128/MyTrendIdeas/calendrier-avent-charlotte-tilbury-wcduf9kcqcoaw8bwqjjk.webp"
                  />
                  <div className="right-description">
                    <h3>C comme Charlotte TILBURY</h3>
                    <p>
                      Les produits de la marque CHARLOTTE TILBURY sont un
                      must-have pour les TikTokeuses. Son calendrier de l'Avent
                      regroupe les produits ses plus tendances.
                    </p>
                    <p>
                      Contenu du calendrier : la crème Magic Cream, l'huile à
                      lèvres Magic Lip Oil Elixir, le spray fixateur Airbrush
                      Flawless Setting Spray, le gloss Collagen Lip Bath,
                      l'enlumineur liquide Hollywood Beauty Light Wand, le blush
                      liquide Pillow Talk Matte Beauty Blush Wand,le mascara
                      Pillow Talk Push Up Lashes, le crayon à lèvres Lip Cheat,
                      le sérum Magic Crystal Elixir, l'eyeliner Rock'n'Kohl, le
                      rouge à lèvres Hot Lips K.I.S.S.I.G, le pinceau Hollywood
                      Complexion.
                    </p>
                    <p>
                      Prix : 200 € pour 15 produits (dont 3 en taille réelle) à
                      découvrir en 15 jours
                    </p>
                    <p>Valeur réelle : plus de 330 €</p>
                    <a
                      href="https://www.sephora.fr/p/charlotte-s-lucky-chest-of-beauty-secrets-P10053591.html"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>200,00 € | Acheter sur SEPHORA</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Calendrier de l'Avent CLARINS"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698979903/MyTrendIdeas/calendrier-clarins-tiousdcsysui1ixrmjq4.png"
                  />
                  <div className="right-description">
                    <h3>C comme CLARINS</h3>
                    <p>
                      Le calendrier de l'Avent de la marque CLARINS est une
                      vraie célébration de Noël avec sa décoration pailletée et
                      dorée. Let étui brillant vous aidera vous aussi à briller
                      de milles feux.
                    </p>
                    <p>
                      Produits : un condensé de tous les produits iconiques
                      (soins ou maquillage) de la marque CLARINS
                    </p>
                    <p>
                      Prix : 120,00 € pour 24 produits à découvrir en 24 jours
                    </p>
                    <p>Valeur réelle : plus de 330,00 €</p>
                    <a
                      href="https://www.clarins.fr/calendrier-de-l-avent-24-cases/CS00996558.html"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>120,00 € | Acheter sur CLARINS</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Calendrier de l'Avent CLINIQUE"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698935786/MyTrendIdeas/calendrier-avent-clinique-vu17qcxmpil2cm4lyawc.webp"
                  />
                  <div className="right-description">
                    <h3>C comme CLINIQUE</h3>
                    <p>
                      Cet étui vert contient les incontournables soin et
                      maquillage de la marque CLINIQUE.
                    </p>
                    <p>
                      Produits : du maquillage (mascara, blush, démaquillant,
                      rouge à lèvres) et des soins (savons, lotions, hydratant,
                      masques, baumes)
                    </p>
                    <p>
                      Prix : 139,00 € pour 24 produits au format voyage à
                      découvrir en 24 jours
                    </p>
                    <p>Valeur réelle : plus de 332,80 €</p>
                    <a
                      href="https://www.sephora.fr/p/calendrier-de-l-avent-clinique---les-incontournables-soin-et-maquillage-P10058275.html"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>139,00 € | Acheter sur SEPHORA</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Calendrier de l'Avent CULT BEAUTY"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698901199/MyTrendIdeas/calendrier-cult-beauty-imml1xf7twvmsbj51nux.png"
                  />
                  <div className="right-description">
                    <h3>C comme CULT BEAUTY</h3>
                    <p>
                      Ce calendrier fait rêver car il renferme pour plus de 1
                      100 € de produits tendances que tout le monde s'arrache.
                      Cerise sur le gateau, 3 tickets en Or ont été cachés dans
                      les calendriers. Chacune d'elle permet de gagner 1 000 €
                      de produits CULT BEAUTY.
                    </p>
                    <p>
                      Marques : 19/99 Beauty, Amika, Augustinus Bader, Benefit
                      travel, Biossance, BYREDO, Conserving Beauty, Cult Beauty,
                      Dieux Skin, Dr. Barbara Sturm, Dr. Dennis Gross Skincare,
                      Dr. LEVY Switzerland, Hair Gain, ELEMIS, Fable & Mane,
                      Glow Recipe, Hair by Sam McKnight, Herlum, Huda Beauty, Jo
                      Loves, Kinship, Medik8, Natasha Denona, OLAPLEX, OUAI
                      Haircare, Paula's Choice, Philip Kingsley, REN Clean
                      Skincare, RÓEN, Saie, Summer Fridays, Sunday Riley,
                      Supergoop!, The Seated Queen, VERDEN, VOTARY
                    </p>
                    <p>
                      Prix : 265 € pour 39 produits (dont 20 en taille réelle) à
                      découvrir en 25 jours
                    </p>
                    <p>Valeur réelle : plus de 1 100 €</p>
                    <a
                      href="https://www.cultbeauty.com/cult-beauty-advent-calendar-2023/14905091.html"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>265,00 € | Acheter sur CULT BEAUTY</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Calendrier de l'Avent ESTÉE LAUDER"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698933676/MyTrendIdeas/calendrier-esth%C3%A9e-lauder-bjaxunyfspxtty4qeiw2.avif"
                  />
                  <div className="right-description">
                    <h3>E comme ESTÉE LAUDER</h3>
                    <p>
                      Pour la créatrice ESTHÉE LAUDER, chaque femme a le pouvoir
                      d'être belle. Pouvoir décuplé grâce aux surprises beauté
                      cachées dans cet écrin : 24 bestsellers soin, maquillage
                      et parfum.
                    </p>
                    <p>
                      Produits : des soins (mousse nettoyante, lotions, crème de
                      nuit, illuminateur, crème de jeunesse, gel, crème et baume
                      contour des yeux), du maquillage (base de teint, baume à
                      lèvres,, rouge à lèvres, gloss, crayon yeux, mascara) et
                      du parfum.
                    </p>
                    <p>
                      Prix : 371,00 € pour 24 produits au format voyage à
                      découvrir en 24 jours
                    </p>
                    <a
                      href="https://www.esteelauder.fr/product/19045/117231/catalogue-produit/coffrets-et-cadeaux/calendrier-de-lavent-24-jours/coffret-de-24-essentiels-beaute"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>371,00 € | Acheter sur ESTHÉE LAUDER </button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Calendrier de l'Avent ESSIE"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698981535/MyTrendIdeas/calendrier-avent-essie-qp9mvqwlhqjnjzhemuln.png"
                  />
                  <div className="right-description">
                    <h3>E comme ESSIE</h3>
                    <p>
                      Ca magnifique sucre d'orge recèle des surprises pour que
                      vous ayez une manucure parfaite pour le réveillon.
                    </p>
                    <p>
                      Produits : 19 vernis à ongles classiques ou vernis gel
                      couture et 6 produits de soin des ongles
                    </p>
                    <p>
                      Prix : 59,95 € pour 24 produits à découvrir en 24 jours
                    </p>
                    <a
                      href="https://www.zalando.fr/essie-essie-24-door-xmas-adventcalendar-calendrier-de-lavent-e4031f03q-t11.html"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>59,95 € | Acheter sur ZALANDO</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Calendrier de l'Avent FENTY"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698917003/MyTrendIdeas/calendrier-fenty-beauty-nppaxmpjwvitqaxgolnm.avif"
                  />
                  <div className="right-description">
                    <h3>F comme FENTY</h3>
                    <p>
                      FENTY BEAUTY, la marque crééé par Rihanna, est devenue un
                      incontournable des réseaux sociaux. Ce calendrier de
                      l'Avent fera donc plaisir aux TikTokeuses de la première
                      heure.
                    </p>
                    <p>
                      Produits : Gloss, Mascara, Blush crème, rouge à lèvres,
                      eyeliner, enlumineur, nettoyant, lotion, crème contour des
                      yeux
                    </p>
                    <p>
                      Prix : 139,00 € pour 12 produits à découvrir en 12 jours
                    </p>
                    <a
                      href="https://www.sephora.fr/p/fenty-beauty-fenty-skin-12-pieces-calendar---calendrier-de-l-avent-P10053920.html#product-info"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>139,00 € | Acheter sur SEPHORA </button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Calendrier de l'Avent FLEURANCE NATURE"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1699001920/MyTrendIdeas/CALENDRIER-AVENT-FLEURANCE-NATURE-n7ejhm7nrwbokr5v135g.jpg"
                  />
                  <div className="right-description">
                    <h3>F comme FLEURANCE NATURE</h3>
                    <p>
                      FLEURANCE NATURE développe des compléments alimentaires et
                      des cosmétiques naturels et bio. L'une de leurs 3 grandes
                      valeurs : le bio à portée de tous avec les prix les plus
                      attractifs.
                    </p>
                    <p>
                      Produits : shampoings, savons, soins pour les mains et le
                      corps et infusions
                    </p>
                    <p>
                      Prix : 47,90 € pour 24 produits à découvrir en 24 jours
                    </p>
                    <p>Valeur réelle : 91,60 €</p>
                    <a
                      href="https://blog.fleurancenature.fr/qui-sommes-nous/notre-mission"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>47,90 € | Acheter sur FLEURANCE NATURE </button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Calendrier de l'Avent L'OCCITANE"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698913907/MyTrendIdeas/calendrier-avent-loccitane-eqqqbqdej9mkfavkqv5n.webp"
                  />
                  <div className="right-description">
                    <h3>L comme L'OCCITANE</h3>
                    <p>
                      L'OCCITANE propose en 2023 non pas un mais trois
                      calendriers de l'Avent. Il y a l'Arbre enchanté en édition
                      limitée que nous avons selectionné mais vous pouvez
                      également choisir le Coffret aux 24 Merveilles à 129 € ou
                      le calendrier réutilisable Infiniment Provence à 209 €.
                    </p>
                    <p>
                      Produits : des soins pour le corps (baume, crème, gel
                      douche, mousse de douche), pour les mains (crèmes mains,
                      savons parfumés et un set de manucure), pour le visage
                      (huile, crème visage, baume pour les yeux) et les cheveux
                      (shampoing et après-shampoing).
                    </p>
                    <p>
                      Prix : 64,00 € pour 24 produits à découvrir en 24 jours
                    </p>
                    <p>Valeur réelle : plus de 121 €</p>
                    <a
                      href="https://fr.loccitane.com/calendrier-de-lavent-larbre-enchante-10CACLA23.html"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>64,00 € | Acheter sur L'OCCITANE</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Calendrier de l'Avent LANCÔME"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698917244/MyTrendIdeas/calendrier-lancome-mwqsnpquxlrmtveatghd.webp"
                  />
                  <div className="right-description">
                    <h3>L comme LANCÔME</h3>
                    <p>
                      La marque de cosmétiques et de produits de luxe sort un
                      coffret bleu et orné de son emblème la rose en son centre.
                      Chaque jour, vous découvrirez un des best-seller soin ou
                      maquillage de LANCÔME.
                    </p>
                    <p>
                      Produits : sélection de produits soin, maquillage et
                      parfum dont la palette Hypnôse Noël 2023, le mascara
                      Hypnôse, le rouge à lèvres Absolu rouge intimatte 299 et
                      le sérum iconique Génifique 20ml
                    </p>
                    <p>
                      Prix : 169,00 € pour 24 produits (dont 6 à taille
                      standard) à découvrir en 24 jours
                    </p>
                    <p>Valeur réelle : plus de 425 €</p>
                    <a
                      href="https://www.sephora.fr/p/calendrier-de-l-avent---24-surprises-parfum--maquillage-et-soin-P10058277.html"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>169,00 € | Acheter sur SEPHORA </button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Calendrier de l'Avent LOOKFANTASTIC"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698896355/MyTrendIdeas/calendrier-avent-lookfantastic-zdvkcvkifrhag9glwybc.webp"
                  />
                  <div className="right-description">
                    <h3>L comme LookFantastic</h3>
                    <p>
                      Contenu du calendrier : soins pour le corps, accessoires
                      beauté, du maquillage et une bougie.
                    </p>
                    <p>
                      Marques : AERY, AVANT Skincare, BEAUTYPRO, CIATÉ, COCO &
                      EVE, COLOR WOW, DISCIPLE, Dr. PAWPAW, ELEMIS, ESPA
                      Skincare, EVE LOM, EYEKO, FIRST AID BEAUTY, GRANDE
                      COSMETICS, ICONIC London, ISOCLEAN, LOOKFANTASTIC, M.A.C.,
                      MEDIK8, NARS, NUDESTIX, ORIGINS, REAL TECHNIQUES, RITUALS,
                      SOL DE JANEIRO
                    </p>
                    <p>
                      Prix : 115 € pour 27 produits (dont 12 en taille réelle) à
                      découvrir en 25 jours
                    </p>
                    <p>Valeur réelle : plus de 625 €</p>
                    <a
                      href="https://www.lookfantastic.fr/beauty-box/calendrier-de-l-avent-beaute-lookfantastic-2023-valeur-de-plus-de-625/11194782.html"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>115,00 € | Acheter sur LOOKFANTASTIC</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Calendrier de l'Avent MAC Cosmetics"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698907183/MyTrendIdeas/calendrier-mac-ubnhozu3segigz3ovpqj.avif"
                  />
                  <div className="right-description">
                    <h3>M comme M.A.C COSMETICS</h3>
                    <p>
                      La marque a sorti en 2023 un magnifique écrin argenté :
                      découvrez le flocon de neige en 3D en ouvrant cette boîte
                      géante. Le calendrier offre une palette des meilleures
                      ventes de M.A.C mais aussi des teintes exclusives.
                    </p>
                    <p>
                      Produits : Mascara, Brillant à lèvres, Rouge à lèvres
                      liquide, Base lèvre incolore, Crayon eyeliner, Fard à
                      paupières, Pinceau lèvres, Crème hydratante
                    </p>
                    <p>
                      Prix : 209,00 € pour 24 produits à découvrir en 24 jours
                    </p>
                    <p>Valeur réelle : plus de 671 €</p>
                    <a
                      href="https://www.maccosmetics.fr/product/31796/120453/produits/collections/collection-noel/le-calendrier-de-lavent"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>209,00 € | Acheter sur M.A.C COSMETICS</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Calendrier de l'Avent MANUCURIST"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698915796/MyTrendIdeas/calendrier-manucurist-ciehz2zeexulcxzrsgup.webp"
                  />
                  <div className="right-description">
                    <h3>M comme MANUCURIST</h3>
                    <p>
                      La marque MANUCURIST se lance dans la tendance calendrier
                      de l'Avent et sort son premier écrin en édition ultra
                      limitée. La boîte est composée de produits et d'outils
                      "nail art" en taille réelle. Tout ce dont vous avez besoin
                      pour une manucure de fête.
                    </p>
                    <p>
                      Produits : des vernis, des poudres, de la paillette et
                      tous les outils indispensables pour une belle manucure.
                      MANUCURIST offre en exclusivité 7 fiches tuto cachées avec
                      vos produits pour réaliser des nails art des plus simples
                      aux plus techniques.
                    </p>
                    <p>
                      Prix : 119,00 € pour 24 produits taille réelle à découvrir
                      en 24 jours
                    </p>
                    <p>Valeur réelle : plus de 278 €</p>
                    <a
                      href="https://www.manucurist.com/products/calendrier-de-lavent"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>119,00 € | Acheter sur MANUCURIST</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Calendrier de l'Avent MARIONNAUD"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698935085/MyTrendIdeas/calendrier-avent-marionnaud-ifcv1iclywde0b8ippye.avif"
                  />
                  <div className="right-description">
                    <h3>M comme MARIONNAUD</h3>
                    <p>
                      Avec son magnifique écrin bleu avec des motifs dorés
                      représentant les étoiles, comètes et constellations,
                      Marionnaud réveille l'esprit de Noël et nous invite à
                      regarder vers le ciel. Vous pouvez également retrouver le
                      "Calendrier de l'Après" de la marque pour continuer la
                      fête même après Noël.
                    </p>
                    <p>
                      Produits : un échantillon des meilleures ventes de
                      produits de maquillage, soin ou des accessoires de la
                      marque Marionnaud.
                    </p>
                    <p>
                      Prix : 37,99 € pour 25 produits au format voyage à
                      découvrir en 25 jours
                    </p>
                    <a
                      href="https://www.marionnaud.fr/idees-cadeaux/coffrets-palettes-maquillage/under-the-stars-calendrier-de-l-avent-marionnaud/p/102608367"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>37,99 € | Acheter sur MARIONNAUD</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Calendrier de l'Avent MAYBELLINE"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698913072/MyTrendIdeas/coffret-maybelline-p9fjoglbqoqesdznptuw.jpg"
                  />
                  <div className="right-description">
                    <h3>M comme MAYBELLINE New York</h3>
                    <p>
                      La marque MAYBELLINE propose de "voir la vie en rose" avec
                      son calendrier de l'Avent 2023 illustré par les
                      gratte-ciel de New-York.
                    </p>
                    <p>
                      Produits : ce calendrier de l'Avent est composé de 24
                      produits incontournables de la marque MAYBELLINE dont des
                      best-sellers tels que le mascara Sky High, l'encre à
                      lèvres Superstay Vinyl Ink ou le blush en crème Cheek Heat
                    </p>
                    <p>
                      Prix : 75,99 € pour 24 produits (dont 24 en taille réelle)
                      à découvrir en 24 jours
                    </p>
                    <a
                      href="https://amzn.to/47hiHFk"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>75,99 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Calendrier de l'Avent NICHE BEAUTY"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698903847/MyTrendIdeas/calendrier-niche-beauty-pocplmbdyf5ttjzkagxu.png"
                  />
                  <div className="right-description">
                    <h3>N comme NICHE BEAUTY</h3>
                    <p>
                      A l'ère du développement durable et du respectde
                      l'environnement, la marque NICHE BEAUTY présente cette
                      année chaque article de son calendrier dans un sac en
                      tissu noir durable avec le jour imprimé sur chacun d'eux.
                      Son calendrier contient plusieurs marques devenues virales
                      sur TikTok cette année.
                    </p>
                    <p>
                      Marques : Aime, Alo Yoga, Augustinus Bader, Byredo,
                      Cosmetics 27, Drunk Elephant, Dr. Barbara Sturm, Hello
                      Love, Irene Forte, Kat Burki, Levy Switzerland, Macrene
                      Actives, Malin + Goetz, MDO by Simon Ourian M.D., MILK
                      Makeup, Oribe, Paula's Choice, Prof. Steinkraus, Slip,
                      Susanne Kaufmann, Syrene, U Beauty, Und Gretel, Virtue
                    </p>
                    <p>
                      Prix : 250 € pour 24 produits (dont 11 en taille réelle) à
                      découvrir en 24 jours
                    </p>
                    <p>Valeur réelle : plus de 1 200 €</p>
                    <a
                      href="https://www.niche-beauty.com/fr-fr/produits/niche-beauty-niche-beauty-advent-calendar-2023/Adventskalender23"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>250,00 € | Acheter sur NICHE BEAUTY</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Calendrier de l'Avent NOCIBE"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698981969/MyTrendIdeas/calendrier-avent-nocibe-tjskjsmxegz0cty7gevd.avif"
                  />
                  <div className="right-description">
                    <h3>N comme NOCIBE</h3>
                    <p>
                      Ca magnifique sucre d'orge recèle des surprises pour que
                      vous ayez une manucure parfaite pour le réveillon.
                    </p>
                    <p>
                      Produits : les bestsellers de la marque afin de prendre
                      soin de votre peau, du maquillage pour être la plus belle
                      et des accessoires pour vous chouchouter.
                    </p>
                    <p>
                      Prix : 32,95 € pour 24 produits à découvrir en 24 jours
                    </p>
                    <p>Valeur réelle : plus de 65.80 €</p>
                    <a
                      href="https://www.nocibe.fr/nocibe-calendrier-de-l-avent-2023-classique-calendrier-de-l-avent-24-cases-s308000"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>32,95 € | Acheter sur NOCIBE</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Calendrier de l'Avent NUXE"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698907887/MyTrendIdeas/calendrier-avent-nuxe-vl0xikzb6onmjlgdznwj.jpg"
                  />
                  <div className="right-description">
                    <h3>N comme NUXE</h3>
                    <p>
                      En ouvrant la magnifique boîte dorée et son noeud rose
                      imprimé, vous découvrirez tous les essentiels de la marque
                      et ses produits les plus emblématiques comme l'Huile
                      Prodigieuse.
                    </p>
                    <p>
                      Produits : les gammes Huile prodigieuse, Prodigieux,
                      Prodigieuse Nuxuriance, Rêve de Miel, Crème fraiche de
                      beauté et Merveillance
                    </p>
                    <p>
                      Prix : 80,36 € pour 24 produits à découvrir en 24 jours
                    </p>
                    <p>Valeur réelle : plus de 671 €</p>
                    <a
                      href="https://amzn.to/49hnQza"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>80,36 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Calendrier de l'Avent ORIGINS"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698915015/MyTrendIdeas/calendrier-origins-fwh4itvywmnelpn6huan.webp"
                  />
                  <div className="right-description">
                    <h3>O comme ORIGINS</h3>
                    <p>
                      Les produits ORIGINS sont présents dans plusieurs
                      calendriers de l'Avent de grandes enseignes beauté car ce
                      sont des best-sellers cette année. Mais la marque a
                      également sortie son propre calendrier, toute de vert
                      vêtue pour symboliser son engagement envers
                      l'environnement.
                    </p>
                    <p>
                      Produits : les produits plébiscités de la marque pour
                      raviver l’éclat de votre peau, booster son hydratation,
                      illuminer la zone sous vos yeux, ciblez les rides et
                      ridules visibles, adoucir votre grain de peau et purifier
                      vos pores
                    </p>
                    <p>
                      Prix : 135,00 € pour 24 produits miniatures à découvrir en
                      24 jours
                    </p>
                    <p>Valeur réelle : plus de 190 €</p>
                    <a
                      href="https://www.origins.eu/fr/fr/product/15341/107661/skincare/skincare-gifts/origins-funhouse/24-essentials-for-face-body#/sku/158335"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>135,00 € | Acheter sur ORIGINS</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Calendrier de l'Avent PAYOT"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698980942/MyTrendIdeas/calendrier-avent-payot-her3liuibxvcjng3o0hg.png"
                  />
                  <div className="right-description">
                    <h3>P comme PAYOT</h3>
                    <p>
                      Dans ce coffret, PAYOT offre non seulement un échantillon
                      de ses best-sellers mais nous propose aussi de découvrir
                      quelques nouveaux produits.
                    </p>
                    <p>
                      Produits : crèmes hydratantes pour visage, corps et mains,
                      shampoing et après-shampoing, masques, toniques, soin pour
                      les lèvres et le contour des yeux et des ampoules au
                      rétinol ou à l'acide hyaluronique
                    </p>
                    <p>
                      Prix : 87,00 € pour 24 produits à découvrir en 24 jours
                    </p>
                    <p>Valeur réelle : plus de 330,00 €</p>
                    <a
                      href="https://www.payot.com/FR/fr/corps/1972-promo-calendrier-de-l-avent-2023-3390150587290.html"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>87,00 € | Acheter sur PAYOT</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Calendrier de l'Avent RITUALS"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698906297/MyTrendIdeas/calendrier-rituals-xiz44ebvxb3yqnci4wqj.webp"
                  />
                  <div className="right-description">
                    <h3>R comme RITUALS</h3>
                    <p>
                      Redécouvrez les produits de la marque RITUALS grâce à son
                      calendrier de l'Avent. Dans cette maison à l'esprit de
                      Noël, ouvrez les portes et fenêtres pour découvrir de
                      merveilleuses surprises pour le bain et le corps mais
                      également pour la maison
                    </p>
                    <p>
                      Produits : Gel douche, Barre de Savon, Wonder Mask, Brume
                      pour le corps, Serum anti-âge, Huile riche pour le corps,
                      Masque réparateur miracle pour les cheveux à la kératine,
                      Gommage pour le corps, Mousse de douche, Crème pour le
                      corps, Baume pour les mains, Mini Bougie, Huile
                      scintillante pour le corps et une décoration de Noël 2023
                    </p>
                    <p>
                      Prix : 79,90 € pour 24 produits à découvrir en 24 jours
                    </p>
                    <p>Valeur réelle : plus de 140 €</p>
                    <a
                      href="https://www.sephora.fr/p/the-ritual-of-advent---calendrier-de-l-avent-2d-P10053045.html"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>79,90 € | Acheter sur SEPHORA</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Calendrier de l'Avent SEPHORA"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698905809/MyTrendIdeas/calednrier-sephora-g5vfr1yhg7eh3kcvkfug.avif"
                  />
                  <div className="right-description">
                    <h3>S comme SEPHORA</h3>
                    <p>
                      Cette année, SEPHORA nous offre une magnifique boite rouge
                      ornée d'étoiles filantes. Des étoiles aussi bien sur que
                      dans le calendrier avec les produits favoris de la
                      marques, pour la plupart tagguées "HOT ON SOCIAL MEDIA".
                    </p>
                    <p>
                      Marques : ANASTASIA BEVERLY HILLS, BENEFIT, BYOMA,
                      CAROLINA HERRERA, CHRISTOPHE ROBIN, DERMALOGICA, DRUNK
                      ELEPHANT, GISOU, HUDA BEAUTY, LANEIGE, LANOLIPS,
                      MANUCURIST, MAKE UP FOR EVER, NARS, NATASHA DENONA, NEOM,
                      PATCHOLOGY, RARE BEAUTY, RITUALS, RMS BEAUTY, SEASONLY,
                      SEPHORA COLLECTION, THE INKEY LIST
                    </p>
                    <p>
                      Prix : 149,90 € pour 26 produits (dont 10 en taille
                      réelle) à découvrir en 24 jours
                    </p>
                    <p>Valeur réelle : plus de 420 €</p>
                    <a
                      href="https://www.sephora.fr/p/calendrier-de-l-avent---26-produits-make-up--soin--capillaire-et-parfum-P10053559.html#product-info"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>149,90 € | Acheter sur SEPHORA</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Calendrier de l'Avent SISLEY"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698909140/MyTrendIdeas/calendrier-avent-sisley-irfxihlqkxli5wgjejr5.jpg"
                  />
                  <div className="right-description">
                    <h3>S comme SISLEY</h3>
                    <p>
                      La marque SISLEY nous présente le calendrier de l'Avent le
                      plus "couture" et le plus luxueux de cette fin d'année
                      2023. L'écrin à lui seul est un cadeau incroyable avec ses
                      broderies et collages. Accessoirement, vous trouverez à
                      l'intérieur les produits phares de la marque.
                    </p>
                    <p>
                      Produits : crème yeux et lèvres anti-âge, soin des lèvres,
                      mascara, baume teinté, rouge à lèvres, eau démaquillante,
                      huile de soin, soin anti-âge, masque de nuit, shampoing
                      volumateur, eau de parfum, savon doux, gel douche et bain
                      et un miroir de poche.
                    </p>
                    <p>
                      Prix : 620 € pour 25 produits (dont 5 en taille réelle) à
                      découvrir en 25 jours
                    </p>
                    <a
                      href="https://www.sisley-paris.com/fr-FR/calendrier-de-l-avent-2023-123511.html"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>620,00 € | Acheter sur SISLEY</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Calendrier de l'Avent TYPOLOGY"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698903110/MyTrendIdeas/calendrier-typology-jlbxmoq6wzunsolgrsx7.webp"
                  />
                  <div className="right-description">
                    <h3>T comme TYPOLOGY</h3>
                    <p>
                      La marque TYPOLOGY a réussi à se faire un nom grâce à ses
                      soins pour la peau naturels, vegan et fabriqués en France.
                      La marque s'est fait un devoir de rester dans ses valeurs
                      de respect de la planète avec ce coffret sobre étudié pour
                      avoir la plus faible empreinte carbone.
                    </p>
                    <p>
                      Contenu du calendrier : Sérum apaisant, Sérum cils &
                      sourcils, Sérum nourrissant, Sérum mains & ongles kératine
                      végétale, Sérum repulpant acide polyglutamique, Sérum
                      matifiant acide azélaïque, Huile à lèvres violet prune,
                      Sérum rides & ridules rétinol, Sérum Exfoliant Acide
                      Glycolique, Assemblage botanique éclat à la rose blanche
                      bio, Crème yeux rides et ridules, Masque lèvre réparateur
                      céramides, Baume teinté orange corail, Baume à lèvres à 9
                      ingrédients, Soin ciblé imperfections bakuchiol, Crème
                      hydratante visage à 9 ingrédients, Crème visage purifiante
                      Zinc PCA, Sérum hydratant acide hyaluronique, Sérum Yeux
                      Caféine, Assemblage botanique à la pivoine, Sérum Vitamine
                      C.
                    </p>
                    <p>
                      Prix : 169,90 € pour 24 produits (dont 15 en taille
                      réelle) à découvrir en 24 jours
                    </p>
                    <p>Valeur réelle : plus de 339 €</p>
                    <a
                      href="https://www.typology.com/products/calendrier-de-l-avent"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>169,90 € | Acheter sur TYPOLOGY</button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AventCalendar;
