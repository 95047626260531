import React from "react";

const AboutMe = () => {
  return (
    <>
      <div className="wrapper">
        <h1>Qui suis-je?</h1>
        <p>
          Jeune maman, je travaille dans le domaine du digital le jour et je
          rédige des articles pour mon blog en m'inspirant de tout ce que je
          vois sur les réseaux sociaux comme TikTok ou Instagram à mes heures
          perdues.
        </p>
        <p>
          Je souhaite à travers ce blog offrir une version compilée de toutes
          les tendances du moment aux personnes qui sont en manque d'idées mais
          ne peuvent pas passer des heures à surfer sur le net pour trouver leur
          bonheur.
        </p>
        <p>
          Si vous souhaitez me faire part de vos commentaires, si vous souhaitez
          des renseignements ou pour tout autre demande, vous pouvez envoyer un
          mail à l'adresse suivante : info@mesideestendances.fr
        </p>
      </div>
    </>
  );
};

export default AboutMe;
