import React from "react";

const GeneralConditions = () => {
  return (
    <div className="wrapper">
      <div className="generalConditions">
        <h1>Mentions légales</h1>
        <p>
          Les présentes conditions fixent les modalités d’utilisation du site
          www.mesideestendances.fr".
        </p>
        <p>
          L’utilisateur reconnait avoir pris connaissance et accepté ces
          mentions légales, que cet usage soit fait à titre personnel ou
          professionnel.
        </p>
        <h2>EDITEUR</h2>
        <p>
          Ce blog personnel non déclaré à la CNIL est édité par Vanessa
          INSULAIRE.
        </p>
        <h2>HEBERGEMENT</h2>
        <p>
          Ce site est hebergé par HOSTINGER dont le siège social est situé au 61
          Lordou Vironos st. 6023 Larnaca, the Republic of Cyprus. L'hébergeur
          détient à ce jour les informations personnelles concernant l’auteur de
          ce site et est joignable par mail : https://www.hostinger.fr/contact.
        </p>
        <h2>PUBLICITE</h2>
        <p>
          Ce site est un blog personnel réalisé par un particulier et financé
          par des fonds propres. Les articles présentés dans les différents
          sujets possèdent des liens d’affiliation.
        </p>

        <h2>COLLECTE ET UTILISATION DES DONNEES A CARACTERE PERSONNEL</h2>
        <p>
          Une donnée personnelle est toute information se rapportant à une
          personne physique identifiée ou identifiable.
        </p>
        <p>
          Une simple consultation du site n’entraîne pas l’enregistrement de
          données à caractère personnel.
        </p>
        <p>
          Néanmoins, en cas d’inscription à la newsletter ou de renseignement de
          la fiche de contact, le site collectera les informations suivantes :
          nom, prénom et adresse mail.
        </p>
        <p>
          Aucune donnée à caractère personnel n’est collectée sans information
          préalable de l’utilisateur.
        </p>
        <p>
          Les informations personnelles collectées en ligne ne peuvent être
          cédées à des tiers.
        </p>

        <span>Définition de la CNIL</span>
        <h2>COOKIES</h2>
        <p>
          Un cookie est un petit fichier informatique, un traceur, déposé et lu
          par exemple lors de la consultation d'un site internet, de la lecture
          d'un courrier électronique, de l'installation ou de l'utilisation d'un
          logiciel ou d'une application mobile et ce, quel que soit le type de
          terminal utilisé (ordinateur, smartphone, liseuse numérique, console
          de jeux vidéos connectée à Internet, etc.).
        </p>
        <span>Définition de la CNIL</span>
        <p>Ce blog n’enregistre pas de cookies.</p>
      </div>
    </div>
  );
};

export default GeneralConditions;
