import React from "react";

const ShortList = () => {
  return (
    <>
      <div className="wrapper">
        <div className="article-info">
          <div>
            <h1>30 JOUETS DE NOEL BIENTÔT EN RUPTURE DE STOCK</h1>
            <p>
              C'est connu : à Noël, tous les enfants rêvent des mêmes jouets.
              Voici la liste des jouets qui s'arracheront à Noël et qu'il faut
              absolument acheter avant la rupture de stock.
            </p>
            <p className="theme-credit">Publié le 31 octobre 2023</p>
            <img
              alt="Jouets de Noël bientôt en rupture"
              src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698773355/MyTrendIdeas/theme-jouets-rupture-co06n9em8brqsjshpzy4.png"
            ></img>
          </div>
        </div>
      </div>
      <div className="grey-back">
        <div className="wrapper">
          <div className="article-details">
            <div className="ideas">
              <div className="ideas-container">
                <div className="idea-container">
                  <img
                    alt="Bitzee - Mon Animal Interactif"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698760122/MyTrendIdeas/pitzee-animal-interactif-cuv46s2ktsmi9mnixjfa.jpg"
                  />
                  <div className="right-description">
                    <h3>Bitzee - Mon Animal Interactif</h3>
                    <p>
                      Bitzee sera le premier ami virtuel en 3D de vos enfants.
                      L'animal apparaît en hologramme. Il faudra prendre soin de
                      lui, jouer avec lui et le nourrir pour le voir grandir. Et
                      plus votre enfant jouera avec Bleetzee, plus vite il
                      débloquera les 15 animaux du boitier.
                    </p>
                    <span>5 ans et +</span>
                    <a
                      href="https://amzn.to/3tRjkal"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>36,99 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Barbie Signature Joyeux Noël 2023"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698741001/MyTrendIdeas/barbie-noel-2023-o6tztx4iisanthns2qtb.jpg"
                  />
                  <div className="right-description">
                    <h3>La Barbie Signature Joyeux Noël 2023</h3>
                    <p>
                      Si chaque année, vous leur offrez la poupée Signature de
                      l'année, et bien sachez que le modèle 2023 est disponible,
                      dans sa robe dorée scintillante. cette poupée
                      resplendissante incarne la magie et l’esprit de Noël.
                    </p>
                    <a
                      href="https://amzn.to/40kPhUG"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>46,00 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Kit slime licorne"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698761131/MyTrendIdeas/kit-slime-licorne-sfhooh1lqoocmhq8trfo.jpg"
                  />
                  <div className="right-description">
                    <h3>Le kit de slime licorne</h3>
                    <p>
                      Ce kit permet de créer son propre slime. Slime flottant,
                      slime phosphorescent, slim de perles, slim de nuage... Les
                      enfants pourront faire appel à leur créativité en s'aidant
                      des 25 compléments et du manuel d'utilisation.
                    </p>
                    <span>8 ans et +</span>
                    <a
                      href="https://amzn.to/3FGykL0"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>29,99 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Pokemon Carry Case Battle Desert Playset"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698743539/MyTrendIdeas/playset-pokemon-ahciv3pqnaknc5sucdyz.jpg"
                  />
                  <div className="right-description">
                    <h3>Le LEGO X-Wing Fighter de Luke Skywalker</h3>
                    <p>
                      Cet ensemble de jeu POKEMON se transforme en sac à dos et
                      accompagne les enfants dans toutes leurs quêtes pour
                      devenir le meilleur dresseur de POKEMON.
                    </p>
                    <span>3 ans et +</span>
                    <a
                      href="https://amzn.to/3snmCBH"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>52,94 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Peluche JJ COCOMELON"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698768732/MyTrendIdeas/peluche-JJ-Cocomelon-ea1fheyg9z1qdjrjghpo.jpg"
                  />
                  <div className="right-description">
                    <h3>La peluche JJ de COCOMELON</h3>
                    <p>
                      Pour les fans de COCOMELON, la poupée à l'effigie de JJ
                      fera leur bonheur à coup sûr. Il faut appuyer sur son
                      ventre pour entendre 7 sons et phrases.
                    </p>
                    <span>A partir de 18 mois</span>
                    <a
                      href="https://amzn.to/3QEsSyo"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>40,71 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="La maison rêvée de Barbie"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1697888935/MyTrendIdeas/maison-revee-Barbie-vkl1un9dxhmybkls2rm5.jpg"
                  />
                  <div className="right-description">
                    <h3>La maison de rêve de Barbie</h3>
                    <p>
                      Cette maison de poupée de luxe possède le toboggan le plus
                      grand à ce jour de la gamme Maison de Rêve de Barbie, avec
                      un design en spirale sur trois niveaux pour s’amuser toute
                      la journée au soleil. Elle est dotée de plus de 75
                      accessoires, de quoi s'amuser du matin au soir.
                    </p>
                    <span>3 ans et +</span>
                    <a
                      href="https://amzn.to/49hZeX6"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>239,99 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Jeu Casse Tête Jump In de SMARTGAMES"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698762090/MyTrendIdeas/casse-tete-jumpin-smartgames-babbunhewlkx8wgdxwzr.jpg"
                  />
                  <div className="right-description">
                    <h3>Le jeu de Casse Tête Jump In de SMARTGAMES</h3>
                    <p>
                      Ce coffret comporte 60 défis pour aider l'enfant à
                      développer ses capacités de concentration, à résoudre des
                      problèmes et sa logique. Il faut sélectionner un défi puis
                      placer les lapins comme indiqué. Le but du jeu : aider les
                      lapins à sauter et à se cacher.
                    </p>
                    <span>7 ans et +</span>
                    <a
                      href="https://amzn.to/49ehFvI"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>17,99 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="LEGO X-Wing Fighter de Luke Skywalker"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698743167/MyTrendIdeas/vaisseau-luke-skywalker-vddriqgxmiq3qoexapsu.jpg"
                  />
                  <div className="right-description">
                    <h3>Le LEGO X-Wing Fighter de Luke Skywalker</h3>
                    <p>
                      Le vaisseau emblématique de Luke Skywalker en LEGO, pour
                      recréer des scènes de la trilogie classique de Star Wars.
                      Le jeu comprend les minifigurines LEGO de Luke Skywalker,
                      la princesse Leia et le général Dodonna avec des armes, y
                      compris le sabre laser de Luke, pour les aventures de jeu
                      de rôle.
                    </p>
                    <span>9 ans et +</span>
                    <a
                      href="https://amzn.to/3FEIXhf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>39,90 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Poupée La Petite Sirène Ariel"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698774025/MyTrendIdeas/ariel-petite-sirene-rc3zltn8hcgc9rmt8fw6.jpg"
                  />
                  <div className="right-description">
                    <h3>La Poupée La Petite Sirène Ariel</h3>
                    <p>
                      Inspiré du film sorti en 2023, cette poupée possède une
                      queue de sirène aux couleurs dégradées, complétée par une
                      nageoire scintillante.
                    </p>
                    <span>3 ans et +</span>
                    <a
                      href="https://amzn.to/46SE404"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>22,99 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Véhicule Chase LA SUPER PATROUILLE"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698461019/MyTrendIdeas/vehicule-chase-supper-patrouille-kihqqaqxge8rqo4enati.jpg"
                  />
                  <div className="right-description">
                    <h3>
                      LE VÉHICULE OFFICIEL DE CHASE DE LA SUPER PATROUILLE
                    </h3>
                    <p>
                      Le film vient à peine de sortir au cinéma et c'est déjà un
                      succès planétaire. Le véhicule 2 en 1 de Chase, réplique
                      officielle de la voiture du film fera plaisir à nos petits
                      fans.
                    </p>
                    <span>3 ans et +</span>
                    <a
                      href="https://amzn.to/40jHgzl"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>59,99 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Asmodee Booster Pokémon sous Blister"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698767596/MyTrendIdeas/pokemon-booster-ecarlate-zcemcssj3tl4gcosbdpm.jpg"
                  />
                  <div className="right-description">
                    <h3>Asmodee Booster Pokémon sous Blister</h3>
                    <p>
                      Les fans de la saga POKEMON pourront complétez leur
                      collection de cartes Pokémon avec le booster blister
                      Ecarlate et Violet EV2, le booster préféré des
                      collectionneurs !
                    </p>
                    <span>6 ans et +</span>
                    <a
                      href="https://amzn.to/3Qk75L9"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>5 ,99 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Le créateur de jeux vidéos PIXICADE"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698744694/MyTrendIdeas/pixicade-mobile-game-maker-gbkpxx8zpz7wbg7lf2cw.jpg"
                  />
                  <div className="right-description">
                    <h3>Le créateur de jeux vidéos PIXICADE</h3>
                    <p>
                      Les enfants dessinent eux-mêmes leur propre jeu vidéo qui
                      prendra vie sur leur tablette grâce à l'application mobile
                      PIXICADE. Ils pourront alors jouer aux jeux qu'ils auront
                      créés eux-mêmes.
                    </p>
                    <span>6 ans et +</span>
                    <a
                      href="https://amzn.to/3FB7N1A"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>22,99 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Jouet musical pour berceau BABY EINSTEIN"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698763245/MyTrendIdeas/jouet-musical-baby-einstein-msb3zrc0flouzf1n8rjm.jpg"
                  />
                  <div className="right-description">
                    <h3>Le jouet musical pour berceau BABY EINSTEIN</h3>
                    <p>
                      Ce petit aquarium très apprécié des tout-petits s'attache
                      facilement au lit avant d'aller dormir. Bébé s'apaise au
                      son des mélodies et s'endort dans une ambiance tamisée,
                      accompagné de ses adorables amis baby einstein. deux
                      petits yeux fatigués se ferment progressivement et les
                      lumières s'éteignent toutes les dix minutes avec le
                      système drift off.
                    </p>
                    <span>Dès la naissance</span>
                    <a
                      href="https://amzn.to/3tXLL6z"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>49,99 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="4 pistolest Laser game rechargeable"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698765763/MyTrendIdeas/pistolet-lasergame-rechargeable-hnaowemts1fpvnwjbkit.jpg"
                  />
                  <div className="right-description">
                    <h3>L'ensemble de 4 Pistolets Rechargeables Laser Tag</h3>
                    <p>
                      Ce kit composé de 4 gilets et pistolets rechargeables
                      offrira des parties interminables jour et nuit, à
                      l'intérieur ou à l'extérieur.
                    </p>
                    <span>A partir de 6 ans</span>
                    <a
                      href="https://amzn.to/3Sk8NP6"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>189,99 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Tamagotchi Nouvelle Génération"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698741547/MyTrendIdeas/tamagotchi-nwfhz5rsuvkoyzpplhrg.jpg"
                  />
                  <div className="right-description">
                    <h3>Le Tamagotchi Nouvelle génération</h3>
                    <p>
                      Les célèbres Tamagotchis s'adaptent à leur époque : écran
                      couleur, boutons tactiles, appareil photo, jeux et réseau
                      social intégré. Les enfants peuvent maintenant se
                      connecter aux Tamagotchi de leurs amis pour échanger des
                      cadeaux et jouer ensemble. On arrête pas le progrès.
                    </p>
                    <a
                      href="https://amzn.to/3SlSqBt"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>58,42 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Kit barbier MELISSA & DOUG"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1697791086/MyTrendIdeas/kit-barbier-kye34t2fkcykbzvin8hp.jpg"
                  />
                  <div className="right-description">
                    <h3>Le Kit du Barbier MELISSA & DOUG</h3>
                    <p>
                      Avec le kit du barbier, votre enfant deviendra un vrai
                      professionnel. Il jouera à brosser, peigner et tondre
                      grâce aux multiples accessoires.
                    </p>
                    <span>3 ans et +</span>
                    <a
                      href="https://amzn.to/3QjbOwr"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>38,95 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Poupée LOL Surprise OMG Dance Dance Dance"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698742575/MyTrendIdeas/poupee-LOL-OMG-dance-gxzr0ghbcdmkievupoyn.jpg"
                  />
                  <div className="right-description">
                    <h3>La Poupée LOL Surprise OMG Dance Dance Dance</h3>
                    <p>
                      Virtuelle s'accompagne de 15 surprises dont des vêtements
                      glamour et des accessoires de créateurs. L'emballage se
                      transforme en piste de danse dès que la poupée est
                      habillée et prête à se déhancher.
                    </p>
                    <span>4 ans et +</span>
                    <a
                      href="https://amzn.to/3SlSqBt"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>34,99 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Tuyaux de bain pour bébé TOMY"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698764862/MyTrendIdeas/tuyaux-de-bain-tomy-owiddywzz9h0v5wfj0xt.jpg"
                  />
                  <div className="right-description">
                    <h3>Les tuyaux debain pour bébé TOMY</h3>
                    <p>
                      Ce kit contient 5 tubes et tuyaux de formes et couleurs
                      différentes à coller sur la paroi du bain pour des heures
                      d'éclaboussures.
                    </p>
                    <span>A partir de 1 an</span>
                    <a
                      href="https://amzn.to/3FCNl0a"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>17,99 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Table de mixage Fisher Price"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1697793616/MyTrendIdeas/table-dj-fp-y9ronh87pbernvqtu15a.jpg"
                  />
                  <div className="right-description">
                    <h3>La table d'activités de DJ</h3>
                    <p>
                      Ce jouet d’éveil interactif est une table de mixage qui
                      comprend un piano avec 3 modes de jeu, 12 activités pour
                      renforcer la motricité fine et 3 niveaux d’apprentissage
                      Éveil Progressif pour accompagner bébé à mesure qu’il
                      grandit. Parfait pour faire de votre progéniture les
                      Martin Garrix ou Ofenbach de la prochaine génération.
                    </p>
                    <span>A partir de 6 mois</span>
                    <a
                      href="https://amzn.to/3FCRIrZ"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>44,99 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Imagier des animaux bilingue VTECH"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698762579/MyTrendIdeas/imagier-animaux-vtech-t71pgfzmhv0tokoll1hc.jpg"
                  />
                  <div className="right-description">
                    <h3>L'imagier des animaux bilingue de VTECH'</h3>
                    <p>
                      Ce livre intéractif permet aux enfants de découvrir le son
                      des animaux ou une anecdote pour chacun d'eux à chque fois
                      qu'il touche une image. Il est en français et en anglais
                      pour une première immersion dans la langue de Shakespeare.
                    </p>
                    <span>Dès 18 mois</span>
                    <a
                      href="https://amzn.to/3QfQ2K9"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>24,99 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Sable magique KINETIC SAND"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698762737/MyTrendIdeas/sable-magique-kinetic-sand-vznp9ua2tjercxuzopc2.jpg"
                  />
                  <div className="right-description">
                    <h3>
                      La malette de construction et Le sable magique KINETIC
                      SAND
                    </h3>
                    <p>
                      Les enfants pourront lancer leur chantier de construction
                      avec le sable magique, un camion, une benne et une boule
                      de démolition.
                    </p>
                    <span>3 ans et +</span>
                    <a
                      href="https://amzn.to/40niDSf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>34,99 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Maison magique de Gabby"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1697887941/MyTrendIdeas/maison-magique-gabby-x8ak82ofgssp04n5ibhc.jpg"
                  />
                  <div className="right-description">
                    <h3>La maison magique de Gabby</h3>
                    <p>
                      Avec cette réplique géante (plus de 60 cm) et intéractive
                      (plusieurs effets sonores) de la maison magique, vos
                      enfants seront immergés dans l'univers de Gabby et prendre
                      le chat-scenseur pour aller rendre visite aux gabbychats.
                    </p>
                    <span>3 ans et +</span>
                    <a
                      href="https://amzn.to/3ShhWbh"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>79,99 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Furby Hasbro Violet"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1697801542/MyTrendIdeas/furby-violet-ykl7htfa2jimjkenpmgq.jpg"
                  />
                  <div className="right-description">
                    <h3>Le Furby Violet</h3>
                    <p>
                      Ce mini compagnon poilu et coloré parle, chante, danse,
                      fait des blagues, s'illumine et réagit lorsque les enfants
                      disent : "On danse", "Imitation", "Dis-moi mon avenir",
                      "Relax" et "Lumières".
                    </p>
                    <span>6 ans et +</span>
                    <a
                      href="https://amzn.to/49fhccy"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>49,99 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="cube anti-stress INFINITY"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698772582/MyTrendIdeas/cube-anti-stress-infinity-djp8bgc3irln5zrfxzss.jpg"
                  />
                  <div className="right-description">
                    <h3>Le cube anti-stress INFINITY</h3>
                    <p>
                      Ce cube magique comporte 36 aimants lui permettant de se
                      transformer en plus de 70 formes. Excellent pour la
                      stimulation sensorielle des jeunes.
                    </p>
                    <span>8 ans et +</span>
                    <a
                      href="https://amzn.to/49iMdfW"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>25,00 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Circuit à Billes Électronique VTECH"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698766462/MyTrendIdeas/circuit-billes-vtech-tzd7nscpwf2oou0ymcnw.jpg"
                  />
                  <div className="right-description">
                    <h3>Le circuit à Billes Électronique VTECH</h3>
                    <p>
                      2 cadeaux en un : un jeu de construction et un jeu de
                      billes. Ce coffret est composé de 126 pièces, 2 modules
                      électriques et 10 marblebilles pour créer un super
                      circuit.
                    </p>
                    <span>A partir de 4 ans</span>
                    <a
                      href="https://amzn.to/3QOpBNf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>55,25 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Arc-en-Ciel en Bois coloré"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698763674/MyTrendIdeas/arc-en-ciel-colore-jn1wwcdas8s2p69sumpj.jpg"
                  />
                  <div className="right-description">
                    <h3>L'Arc-en-Ciel en Bois coloré</h3>
                    <p>
                      Cette tour d'empilage pour enfants forme la motricité
                      fine, la vision spatiale et la créativité de manière
                      ludique.
                    </p>
                    <span>1 an et +</span>
                    <a
                      href="https://amzn.to/3tVbV9Z"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>27,95 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Polly Pocket Coffret Forêt des Licornes"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698766902/MyTrendIdeas/coffret-polly-pocket-licorne-i6h4xof9k2ufmkkewvfx.jpg"
                  />
                  <div className="right-description">
                    <h3>Le coffret Polly Pocket Coffret Forêt des Licornes</h3>
                    <p>
                      Ce coffret Forêt des Licornes, en forme de licorne et avec
                      une corne transparente remplie de paillettes contient
                      plusieurs accessoires pour le goûter (un service à thé,
                      une table et des chaises et d'autres élémeents à thème).
                    </p>
                    <span>4 ans et +</span>
                    <a
                      href="https://amzn.to/3QCI5QC"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>19,99 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Le sac animal intéractif"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698744224/MyTrendIdeas/sac-interactif-purse-pet-cxdxf2ixvt5pkepwaboj.jpg"
                  />
                  <div className="right-description">
                    <h3>Le sac chaton avec effets lumineux PURSE PETS</h3>
                    <p>
                      Offrez-lui son premier animal intéractif en format sac à
                      main. Non seulement ce sac lui servira à ranger ses objets
                      préférés, mais il intéragira également avec l'enfant :
                      appuyez sur le nez du chaton pour faire défiler les
                      couleurs de l'arc-en-ciel et choisir celle qui correspond
                      à votre humeur ou maintenez son nez enfoncé pour passer en
                      « mode amoureux », ses yeux deviennent roses.
                    </p>
                    <span>4 ans et +</span>
                    <a
                      href="https://amzn.to/40fyy5f"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>18,90 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="La fée volante magique HATCHIMALS"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698772013/MyTrendIdeas/fee-volante-hatchimals-w2uy8lntu9vqwm8vo1iq.jpg"
                  />
                  <div className="right-description">
                    <h3>La fée volante magique HATCHIMALS</h3>
                    <p>
                      Cette fée volante s'illumine et se dirige avec la main.
                      Dotée d'un nouveau design, elle s'illumine et possède de
                      grandes ailes pailletées, une superbe robe et un
                      maquillage coloré.
                    </p>
                    <span>6 ans et +</span>
                    <a
                      href="https://amzn.to/3u1fbR4"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>40,01 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Le couteau pour enfants CHEFCLUB KIDS"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698764225/MyTrendIdeas/couteau-pour-enfants-chefclub-kids-dptvfhzrxbsh3desldwf.jpg"
                  />
                  <div className="right-description">
                    <h3>Le couteau pour enfants CHEFCLUB KIDS</h3>
                    <p>
                      Pour les petits chefs fans de Ratatouille et qui veulent
                      faire comme les grands, ce couteau a été étudié pour
                      éviter tout accident. Ce kit inclus un couteau, un
                      protège-doigts et le protège-lame.
                    </p>
                    <span>7 ans et +</span>
                    <a
                      href="https://amzn.to/45OEi7b"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>22,89 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShortList;
