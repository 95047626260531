import React from "react";
import { useNavigate } from "react-router-dom";
/* import Item from "./Item";
import FutureItems from "./FutureItems"; */

function MainContent() {
  const navigate = useNavigate();
  return (
    <div className="main-content">
      {/*       <h1>TOUTES LES IDEES TENDANCES</h1>
       */}
      <div className="item" onClick={() => navigate("/cadeau-tendance-femmes")}>
        <img
          alt="Cadeaux adultes tendances 2023"
          src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698374667/MyTrendIdeas/theme-cadeaux-tendances-s5mmpyr4tmk4bl3gmnyw.png"
        />
        <div>
          <h3 className="subtitle">
            36 IDEES DE CADEAUX TENDANCES INSPIRÉES DE TIKTOK EN 2023
          </h3>
          <p>
            Tout le monde le sait. Tiktok est un faiseur de tendance. Chaque
            vidéo qui devient "viral" créé une nouvelle tendance. Vous trouverez
            ici une liste de produits qui cartonnent sur TikTok et qui sont les
            idées cadeaux les plus demandées cette année.
          </p>
          <span>Publié le 18 octobre 2023</span>
        </div>
      </div>
      <div className="item" onClick={() => navigate("/cadeau-tendance-ado")}>
        <img
          alt="Cadeaux adolescents tendances 2023"
          src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698341006/MyTrendIdeas/theme-cadeaux-adolescents-xa12xvt406exfqksg5jn.jpg"
        />
        <div>
          <h3 className="subtitle">
            40 IDEES DE CADEAUX TENDANCES POUR LES ADOLESCENTS INSPIREES DE
            TIKTOK
          </h3>
          <p>
            Ah ces adolescents ! Ils nous aiment mais pourtant ils nous
            demandent de les déposer le plus loin possible du lycée et revendent
            chaque année les cadeaux qu'on leur a offert avec amour.
            <br />
            Pour leur faire plaisir cette année, il y a la solution de
            l'enveloppe avec un ou plusieurs billets glissés à l'intérieur. Ou
            alors, vous pouvez choisir dans cette liste des meilleurs cadeaux à
            leur offrir, inspirée directement des tendances TikTok.
          </p>
          <span>Publié le 18 octobre 2023</span>
        </div>
      </div>
      <div
        className="item"
        onClick={() => navigate("/cadeau-tendance-enfants")}
      >
        <img
          alt="Jouets Noël 2023"
          src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698630375/MyTrendIdeas/theme-jouets-lot6qoe1txie9mjyeqkc.png"
        />
        <div>
          <h3 className="subtitle">
            28 IDEES DE JOUETS TENDANCES A OFFRIR A NOEL
          </h3>
          <p>
            La fête d'Halloween est à peine passée, la douce voix de Mariah
            Carey commence à susurrer à notre oreille son indémodable chanson
            "All I want for Christmas", les feuilletons de Noel envahissent nos
            écrans. On y est, les préparatifs des fêtes de fin d'année
            commencent. A vos marques, prêt, partez ... pour shopper les cadeaux
            tendances qui mettront des paillettes dans les yeux de nos enfants.
          </p>
          <span>Publié le 18 octobre 2023</span>
        </div>
      </div>
      <div
        className="item"
        onClick={() => navigate("/best-seller-jouets-2023")}
      >
        <img
          alt="Jouets Noël 2023 bientôt en rupture"
          src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698773355/MyTrendIdeas/theme-jouets-rupture-co06n9em8brqsjshpzy4.png"
        />
        <div>
          <h3 className="subtitle">
            30 JOUETS DE NOEL BIENTÔT EN RUPTURE DE STOCK
          </h3>
          <p>
            C'est connu : à Noël, tous les enfants rêvent des mêmes jouets.
            Voici la liste des jouets qui s'arracheront à Noël et qu'il faut
            absolument acheter avant la rupture de stock.
          </p>
          <span>Publié le 31 octobre 2023</span>
        </div>
      </div>
      <div
        className="item"
        onClick={() => navigate("/cadeau-de-naissance-fait-main")}
      >
        <img
          alt="Cadeaux de naissance fait-main"
          src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698849099/MyTrendIdeas/theme-cadeau-naissance-fait-main-wnwmf4pce74mves7b3hs.jpg"
        />
        <div>
          <h3 className="subtitle">
            15 idées de cadeaux de naissance fait-main
          </h3>
          <p>
            Ca y est. Votre fille / fils / petit-fils / petite-fille / neveu /
            nièce ... est né et vous êtes emerveillé devant ces petits yeux
            brillants, sa peau douce et ce gazouillis de bébé inimitable. Il
            faut maintenant lui trouver un cadeau à la hauteur de votre émotion
            et de l'amour inestimable que vous ressentez déjà. Pourquoi pas lui
            offrir un cadeau personnalisé et fait-main qu'il ou elle gardera
            toute sa vie.
          </p>
          <span>Publié le 2 novembre 2023</span>
        </div>
      </div>
      <div
        className="item"
        onClick={() => navigate("/offrir-calendrier-avent")}
      >
        <img
          alt="Calendriers de l'avent"
          src="https://res.cloudinary.com/dug8eeke1/image/upload/v1699001400/MyTrendIdeas/th%C3%A8me-calendrier-avent-atgq8kkwzmcm3icnclck.png"
        />
        <div>
          <h3 className="subtitle">
            25 idées de calendriers de l'Avent pour adultes à offrir
          </h3>
          <p>
            Chaque année, les enfants attendent avec impatience de recevoir leur
            calendrier de l'Avent. Depuis quelques années, les adultes peuvent
            également ressentir cette excitation car beaucoup de boutiques
            développent leur propre calendrier de l'Avent avec un condensé de
            leurs meilleurs produits à prix abordable.
            <br />
            Voici une liste de calendriers de Noël à offrir ou à s'offrir pour
            les fêtes de fin d'année 2023.
          </p>
          <span>Publié le 4 novembre 2023</span>
        </div>
      </div>
      {/*       <div className="item" onClick={() => navigate("/2323101807")}>
        <img
          alt="Jouets tendances TikTok 2023"
          src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698374667/MyTrendIdeas/theme-cadeaux-tendances-s5mmpyr4tmk4bl3gmnyw.png"
        />
        <div>
          <h3 className="subtitle">36 JOUETS TENDANCES SUR TIKTOK EN 2023</h3>
          <p>
            Tout le monde le sait. Tiktok est un faiseur de tendance. Chaque
            vidéo qui devient "viral" créé une nouvelle tendance. Vous trouverez
            ici une liste de produits qui cartonnent sur TikTok et qui sont les
            idées cadeaux les plus demandées cette année.
          </p>
          <span>Publié le 18 octobre 2023</span>
        </div>
      </div> */}
      {/*       <div className="item" onClick={() => navigate("/2323101805")}>
        <img
          alt="Thème Halloween"
          src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698456898/MyTrendIdeas/theme-halloween-2023-borhrwmarvrbqnlxdgs7.png"
        />
        <div>
          <h3 className="subtitle">
            7 IDEES DE DEGUISEMENTS TENDANCES POUR HALLOWEEN EN 2023
          </h3>
          <p>
            Halloween, c'est dans quelques jours. Avez-vous déjà choisi votre
            déguisement ou êtes vous plutôt cette personne spontanée qui se
            connecte 24h avant sur Amazon pour dénicher un costume avec une
            livraison Prime?
            <br />
            Trois mois ou trois jours avant, la question se pose : en quoi
            vais-je me déguiser cette année ? Vous pouvez vous ja jouer
            classique en mode sorcière ou fantôme. Ou alors vous pouvez choisir
            un des 7 thèmes tendances pour Halloween 2023.
            <br />
            Barbie, Mercredi Addams ou une Bridgerton. A Halloween, tout est
            permis !
          </p>
          <span>Publié le 18 octobre 2023</span>
        </div>
      </div> */}
      {/*       <div className="item" onClick={() => navigate("/2323101804")}>
        <img
          alt="Cadeaux chiens et chats tendances 2023"
          src={ImagePetsGifts}
        />
        <div>
          <h3 className="subtitle">
            Mes idées de cadeaux de Noël tendances pour nos chiens et chats en
            2023
          </h3>
          <p>Publié le 18 octobre 2023</p>
          <p classname="credit">CREDIT : ??</p>
          <p>Pour nos compagnons à 4 pattes"</p>
        </div>
      </div> */}
      {/*       <h1>MES IDEES TENDANCES A VENIR</h1>

      <FutureItems
        subtitle="Mes idées tendances de destinations pour le nouvel an 2024"
        creationDate="Date de publication estimée : le 23 octobre 2023"
        description="Au soleil ou sous les flocons de neige, devant la tour Eiffel de Paris ou l'Opera House de Sydney, venez découvrir les destinations tendances pour passer un réveillon du nouvel an 2024 inoubliable."
        image="Nouvel an 2023"
        imageLink={ImageNewYear}
        credit="nck_gsl, PIXABAY"
      /> */}
    </div>
  );
}

export default MainContent;
