import React from "react";

function Footer() {
  return (
    <div className="wrapper">
      <div className="footer">
        <nav>
          <ul>
            <li>
              <a href="/">Accueil</a>
            </li>
            <li>
              <a href="/mentions-legales">Mentions légales</a>
            </li>
            <li>
              <a href="/about-me">Qui suis-je ?</a>
            </li>
          </ul>
        </nav>
        <span>
          Ce site participe au programme Partenaires d'Amazon EU, un programme
          d'affiliation conçu pour permettre à des sites de percevoir une
          rémunération grâce à la création de liens vers Amazon.fr.
          <br />
          Ce site participe au programme Partenaires d'Awin.
          <br />
          <a href="https://www.freepik.com/free-photo/knitted-children-s-clothing-light-background-with-accessories_9434087.htm#query=newborn%20background&position=4&from_view=keyword&track=ais">
            Image by pvproductions
          </a>
          on Freepik on Freepik
        </span>
      </div>
    </div>
  );
}

export default Footer;
