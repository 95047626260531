import React from "react";
import MainContent from "./MainContent";
/* import SideContent from "./SideContent";
 */
function Content() {
  return (
    <div className="grey-back">
      <MainContent></MainContent>
      {/*       <SideContent></SideContent> */}
    </div>
  );
}

export default Content;
