import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";

import Header from "./components/Header";
/* import NavigationMenu from "./components/NavigationMenu";
 */
import Home from "./containers/Home";
import HalloweenIdea from "./containers/HalloweenIdea";
import HalloweenKidsIdea from "./containers/HalloweenIdeaKids";
import ChristmasForAdults from "./containers/ChristmasForAdults";
import ChristmasForKids from "./containers/ChristmasForKids";
import ChristmasForPets from "./containers/ChristmasForPets";
import ChristmasForTeens from "./containers/ChristmasForTeens";
import ShortlistChristmas from "./containers/ShortlistChristmas";
import HomemadeNewbornGifts from "./containers/HomemadeNewbornGifts";
import AventCalendars from "./containers/AventCalendar";
import GeneralConditions from "./containers/GeneralConditions";
import TikTokToys from "./containers/TikTokToys";
import ContactForm from "./containers/ContactForm";
import AboutMe from "./containers/AboutMe";

import Footer from "./components/Footer";

import "./App.css";

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/mentions-legales",
    element: <GeneralConditions />,
  },
  {
    path: "/contact",
    element: <ContactForm />,
  },
  {
    path: "/about-me",
    element: <AboutMe />,
  },
  {
    path: "/cadeau-tendance-femmes",
    element: <ChristmasForAdults />,
  },
  {
    path: "/cadeau-tendance-enfants",
    element: <ChristmasForKids />,
  },
  {
    path: "/cadeau-tendance-ado",
    element: <ChristmasForTeens />,
  },
  {
    path: "//best-seller-jouets-2023",
    element: <ShortlistChristmas />,
  },
  {
    path: "/cadeau-de-naissance-fait-main",
    element: <HomemadeNewbornGifts />,
  },
  {
    path: "/offrir-calendrier-avent",
    element: <AventCalendars />,
  },
  /*   {
    path: "/2323101805",
    element: <HalloweenIdea />,
  },
  {
    path: "/2323101806",
    element: <HalloweenKidsIdea />,
  }, */
  /*  {
    path: "/2323101807",
    element: <TikTokToys />,
  }, */
]);

function App() {
  return (
    <>
      <Header />
      {/*       <NavigationMenu />
       */}{" "}
      <RouterProvider router={router} />
      <Footer />
    </>
  );
}

export default App;
