import React from "react";

const ChristmasForAdults = () => {
  return (
    <>
      <div className="wrapper">
        <div className="article-info">
          <div>
            <h1>
              36 IDEES DE CADEAUX POUR ADULTES TENDANCES INSPIRÉES DE TIKTOK EN
              2023
            </h1>
            <img
              alt="idées cadeaux inspirés tiktok"
              src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698374667/MyTrendIdeas/theme-cadeaux-tendances-s5mmpyr4tmk4bl3gmnyw.png"
            ></img>
            <p>
              Tout le monde le sait. Tiktok est un faiseur de tendance. Chaque
              vidéo qui devient "viral" créé une nouvelle tendance. Vous
              trouverez ici une liste de produits qui cartonnent sur TikTok et
              qui sont les idées cadeaux les plus demandées cette année.
            </p>
            <span>Publié le 21 octobre 2023</span>
          </div>
        </div>
      </div>
      <div className="grey-back">
        <div className="wrapper">
          <div className="article-details">
            <div className="ideas">
              <div className="ideas-container">
                <div className="idea-container">
                  <img
                    alt="Taie d'oreiller en soie"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1697823681/MyTrendIdeas/taie-oreiller-rjeizklvfkojmrmcuyot.jpg"
                  />
                  <div className="right-description">
                    <h3>La taie d'oreiller en soie</h3>
                    <p>
                      La taie d'oreiller en soie est devenu l'accessoire mode
                      pour avoir une peau et des cheveux en bonne santé. Elles
                      aident à réduire l'acnée, les frisottis et les cassures de
                      cheeux.
                    </p>
                    <a
                      href="https://amzn.to/45WpnI1"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>13,89 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Le legging LULULEMON"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698227722/MyTrendIdeas/legging-taille-haute-lululemon-mhah2ye2icagjdluu0nn.webp"
                  />
                  <div className="right-description">
                    <h3>Le legging LULULEMON Align taille haute</h3>
                    <p>
                      On ne présente plus la marque LULULEMON ni le phénomène
                      leggings sur les réseaux sociaux. Les 2 fusionnées donnent
                      ce legging taille haute ultra tendance.
                    </p>
                    <a
                      href="https://www.lululemon.fr/fr-fr/p/legging-lululemon-align%E2%84%A2-cotele-taille-haute-64%C2%A0cm/prod11240313.html?dwvar_prod11240313_color=0001"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>118,00 € | Acheter sur LULULEMON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Les bombes de bain arc-en-ciel"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1697824385/MyTrendIdeas/bombe-bain-sjo1rj7hmx80t8ohjqst.jpg"
                  />
                  <div className="right-description">
                    <h3>Les bombes de bain arc-en-ciel</h3>
                    <p>
                      Les bombes de bain arc-en-ciel avec des huiles
                      essentielles pour hydrater la peau.
                    </p>
                    <a
                      href="https://amzn.to/47fkHOD"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>18,99 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Le blender transportable"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1697853119/MyTrendIdeas/blender-transportable-qcwlolwk6frzwfy5cueu.jpg"
                  />
                  <div className="right-description">
                    <h3>Le blender transportable</h3>
                    <p>
                      Ce blender est composé d'un corps en acier inoxydable
                      équipé de 4 lames. Comme accessoires, nous avons 2 gourdes
                      fournis. Il reste juste à réaliser son smoothie préféré et
                      le transporter grâce au couvercle de la gourde.
                    </p>
                    <a
                      href="https://amzn.to/3FFOxQu"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>34,90 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Pantoufles en fourrure colorée"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1697824704/MyTrendIdeas/pantoufles-fourrure-kd6jfl3vk9pd4ynsrdj2.jpg"
                  />
                  <div className="right-description">
                    <h3>LES PANTOUFLES EN FOURRURE COLOREE</h3>
                    <p>
                      Pour garder les pieds au chaud cet hiver dans une
                      pantoufle remplie de douceur et de couleurs.
                    </p>
                    <a
                      href="https://amzn.to/46T9B1J"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>18,69 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Kit professionnel de pinceaux pour le maquillage"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1697825047/MyTrendIdeas/kit-pinceaux-j5pb2r6co9b39ypwkqm4.jpg"
                  />
                  <div className="right-description">
                    <h3>Le Kit professionnel de pinceaux pour le maquillage</h3>
                    <p>Pour se maquiller comme une professionnelle.</p>
                    <a
                      href="https://amzn.to/3Qb9l76"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>49,27 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Imprimante photo KODAK"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1697826123/MyTrendIdeas/imprimante-photo-jbmo6ux9xhkcuf86psfi.jpg"
                  />
                  <div className="right-description">
                    <h3>L'imprimante photo pour portable KODAK</h3>
                    <p>
                      Il n'a jamais été aussi facile de nos jours d'immortaliser
                      chaque instant avec nos portables. Grâce à cette
                      imprimante, il est maintenant possible de les imprimer de
                      chez soi ou d'ailleurs et de les tapisser ses murs ou les
                      épingler sur le frigo.
                    </p>
                    <a
                      href="https://amzn.to/40fKXpH"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>149,99 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Briquet électrique rechargeable USB"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1697851435/MyTrendIdeas/briquet-electrique-hnef7gw2at1txzs5w6db.jpg"
                  />
                  <div className="right-description">
                    <h3>Le briquet électrique rechargeable</h3>
                    <p>
                      Pour les fumeurs mais aussi les non fumeurs qui adorent
                      allumer des bougies.
                    </p>
                    <a
                      href="https://amzn.to/3SDGkUP"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>11,99 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Nettoyeur de pinceaux de maquillage"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1697851228/MyTrendIdeas/nettoyeur-pinceaux-qmdljootnfesivl6x4dv.jpg"
                  />
                  <div className="right-description">
                    <h3>Le nettoyeur et sécheur de pinceaux de maquillage</h3>
                    <p>
                      Pour les tiktokeuses fans de tutos maquillage, ce kit vous
                      permet d'avoir des pinceaux toujours propres en un geste
                      simple.
                    </p>
                    <a
                      href="https://amzn.to/3Mn1eDF"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>40,00 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Lampe coucher de soleil"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1697852314/MyTrendIdeas/lampe-coucher-soleil-duiw29pr9o0owyzqtstp.jpg"
                  />
                  <div className="right-description">
                    <h3>La lampe coucher de soleil</h3>
                    <p>
                      Accessoire des tiktokeuses pour une lumière effet coucher
                      de soleil sur leurs vidéos.
                    </p>
                    <a
                      href="https://amzn.to/3FC8HLl"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>28,99 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Le rouleau glacé pour le visage"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1697852837/MyTrendIdeas/rouleau-glac%C3%A9-exwpf3glxsrdegrtoehu.jpg"
                  />
                  <div className="right-description">
                    <h3>Le rouleau glacé pour le visage</h3>
                    <p>
                      Ce rouleau, à placer au congelateur pendant 4 heures avant
                      utilisation, soulage les yeux gonflés, calme les rougeurs
                      de la peau
                    </p>
                    <a
                      href="https://amzn.to/3MLxS23"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>19,99 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Brosse de massage du cuir chevelu"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1697853728/MyTrendIdeas/brosse-massage-cheveu-xcmeqdivtjttfcpdxf1m.jpg"
                  />
                  <div className="right-description">
                    <h3>La brosse de massage du cuir chevelu</h3>
                    <p>
                      Cette brosse en silicone permet d'éliminer tous les
                      résidus de la pollution quotidienne et de stimuler la
                      pousse des cheveux. Effet relaxant garanti.
                    </p>
                    <a
                      href="https://amzn.to/3FCaQGI"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>9,99 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Mini réfrigérateur à cosmétiques SKINCARE"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1697854293/MyTrendIdeas/mini-refrigerateur-tsleosczyymftyrlcuxf.jpg"
                  />
                  <div className="right-description">
                    <h3>Le mini réfrigérateur à cosmétiques SKINCARE</h3>
                    <p>
                      Ce mini réfrigérateur est idéal pour conserver vos
                      cosmétiques ou soins au frais et augmenter leur
                      efficacité. Avec sa petite taille, il est facilement
                      transportable et peut se poser dans la chambre ou la salle
                      de bain.
                    </p>
                    <a
                      href="https://amzn.to/3scJ7cw"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>59,99 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Lampe lune"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1697854499/MyTrendIdeas/lampe-lune-mslwyygjhpijfihe3v9o.jpg"
                  />
                  <div className="right-description">
                    <h3>La lampe lune</h3>
                    <p>
                      Avec cette lampe, plus besoin d'aller à l'extérieur pour
                      aller voir la lune tellement l'effet 3D est
                      impressionnant. Contrôle à distance, 16 couleurs
                      selectionnables.
                    </p>
                    <a
                      href="https://amzn.to/49a5tfD"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>20,99 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Clavier pour portable et téléphone iOS"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1697854971/MyTrendIdeas/clavier-portable-zhnswzkj9dj4wcsxzxsx.jpg"
                  />
                  <div className="right-description">
                    <h3>Le clavier pour portables et tablettes iOS</h3>
                    <p>
                      Ce clavier bluetooth clignote de 7 couleurs différentes et
                      est compatible avec vos iPhone et Ipad.
                    </p>
                    <a
                      href="https://amzn.to/3SnGPlK"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>24,50 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="La gourde avec marquage de temps"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1697825472/MyTrendIdeas/gourde-mesure-temps-usj9nbwlybjdfdhv2gcz.jpg"
                  />
                  <div className="right-description">
                    <h3>La gourde avec marquage de temps</h3>
                    <p>
                      Pour les sportives tendances, cette gourde avec marquage
                      de temps vous rappelle de prendre la bonne quantité d'eau
                      et vous permet de rester bien hydraté tout au long de la
                      journée.
                    </p>
                    <a
                      href="https://amzn.to/3SsQ2ca"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>11,99 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Ruban LED"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1697856008/MyTrendIdeas/ruban-led-lmgdmrcgdtrtuwpe5zce.jpg"
                  />
                  <div className="right-description">
                    <h3>Les bandes lumineuses LED</h3>
                    <p>
                      Les rubans LED sont incontournables pour créer une
                      ambiance dans votre salon ou votre chambre, illuminer vos
                      fêtes de 16 millions de couleurs.
                    </p>
                    <a
                      href="https://amzn.to/3FGmmAM"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>16,99 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Sèche-cheveu volumisant REVLON"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1697856732/MyTrendIdeas/seche-cheveu-volumisant-zzt9q7r1rrxwd54ueapn.jpg"
                  />
                  <div className="right-description">
                    <h3>Le sèche-cheveu volumisant REVLON</h3>
                    <p>
                      Ce sèche-cheveu permet de créer du volume aux racines et
                      d'obtenir des boucles magnifiques sur les longueurs.
                    </p>
                    <a
                      href="https://amzn.to/45TRrfb"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>38,99 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Hamac avec moustiquaire"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1697857143/MyTrendIdeas/hamac-moustiquaire-g90zqvzwwqcwrw0vwdxu.jpg"
                  />
                  <div className="right-description">
                    <h3>Le hamac et sa moustiquaire</h3>
                    <p>
                      Pour les baroudeurs ou pour vos prochaines vacances sac à
                      dos, ce hamac ultra léger et sa capacité de charge de
                      300kg vous accueillera avec vos amis, vos enfants, votre
                      chien...
                    </p>
                    <a
                      href="https://amzn.to/3FELmsc"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>25,99 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Perfecteur de teint Maybelline"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1697858362/MyTrendIdeas/perfecteur-teint-maybelline-kr9hq4u8sik53r1j7kpe.webp"
                  />
                  <div className="right-description">
                    <h3>Le perfecteur de teint MAYBELLINE</h3>
                    <p>
                      Ce perfecteur de teint allie base de teint, correcteur, BB
                      crème et enlumineur. Ce produit tout-en-un est pratique et
                      facile à appliquer.
                    </p>
                    <a
                      href="https://amzn.to/3Sk73oW"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>14,99 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Coffret rouge à lèvres Maybelline"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1697859266/MyTrendIdeas/coffret-rouge-a-levres-maybelline-e7sz3xbbozufv3k23bfd.jpg"
                  />
                  <div className="right-description">
                    <h3>Le coffret de rouge à lèvres MAYBELLINE</h3>
                    <p>
                      Coffret d'un ensemble de 6 encres à lèvres intenses au
                      fini mat velouté à longue tenue, Constitué de 6 teintes
                      pour tous les styles
                    </p>
                    <a
                      href="https://amzn.to/3FGmyQw"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>35,15 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Spray imperméabilisant COLOR WOW"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1697859868/MyTrendIdeas/spray-impermeabilisant-color-wow-crafipqfim4o4ozqtglt.webp"
                  />
                  <div className="right-description">
                    <h3>Le spray imperméabilisant COLOR WOW</h3>
                    <p>
                      Ce spray cartonne sur less réseaux pour son pouvoir
                      imperméabilisant. Plus besoinde consulter la météo avant
                      son brushing.
                    </p>
                    <a
                      href="https://amzn.to/3u28mPd"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>24,75 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Crème pour le corps SOL DE JANEIRO"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1697860283/MyTrendIdeas/creme-corps-soldejaneiro-xyhcadf65fddv0siks2i.webp"
                  />
                  <div className="right-description">
                    <h3>La crème pour le corps SOL DE JANEIRO</h3>
                    <p>
                      Cette crème pour le corps a une odeur agréable et laisse
                      la peau irisée.
                    </p>
                    <a
                      href="https://amzn.to/46RdYKV"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>43,99 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Bouteille portable pour chien"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1697863491/MyTrendIdeas/bouteille-chien-nkgtypdl90ezxjkswqnq.jpg"
                  />
                  <div className="right-description">
                    <h3>La bouteille portable pour chiens</h3>
                    <p>
                      Cette bouteille d'eau est idéale pour les promenades et
                      les voyages avec votre chien ou votre chat, et comprend un
                      mousqueton de bouteille qui peut être attaché à votre sac
                      à dos, à votre caisse, à votre landau ou à votre boîte à
                      gants.
                    </p>
                    <a
                      href="https://amzn.to/46MHTUq"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>17,98 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Pinceaux exfoliants pour les lèvres"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1697864088/MyTrendIdeas/pinceaux-exfoliants-u3hxk5ufsc3npjkw5nwt.jpg"
                  />
                  <div className="right-description">
                    <h3>Les pinceaux exfoliants pour les lèvres</h3>
                    <p>Pour des lèvres douces et exfoliées.</p>
                    <a
                      href="https://amzn.to/469hrn3"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>5,99 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Table de chevet portable"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1697864282/MyTrendIdeas/table-chevet-portable-isuvv5zwbwb5nat1h2or.jpg"
                  />
                  <div className="right-description">
                    <h3>La table de chevet pliante</h3>
                    <p>
                      Avec cette table de chevet pratique, votre iPad, votre
                      téléphone portable, vos livres, vos réveils, vos boissons
                      ou tout autre objet que vous pouvez emporter au lit est à
                      une longueur de bras.
                    </p>
                    <a
                      href="https://amzn.to/3QEiF4X"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>26,99 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Porte clé magnétique nuage"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1697864495/MyTrendIdeas/porte-cles-magnetique-nuage-sh5fqocnfqen6kb7orkc.jpg"
                  />
                  <div className="right-description">
                    <h3>Le porte clés magnétique nuage</h3>
                    <p>
                      Porte clé original et mignon. Existe en bleu ou en blanc.
                    </p>
                    <a
                      href="https://amzn.to/3QdbgrY"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>11,99 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Sacoche de téléphone en bandoulière"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1697874041/MyTrendIdeas/sac-telephone-bandoulliere-iexqnfwgy36z5d90gxfk.jpg"
                  />
                  <div className="right-description">
                    <h3>La sacoche de téléphone en bandoulière</h3>
                    <p>
                      Cet accessoire tendance sur les réseaux a la taille idéale
                      pour y glisser votre téléphone, 6 cartes, des billets et
                      de la monnaie.
                    </p>
                    <a
                      href="https://amzn.to/3tWuWJh"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>15,99 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="chouchous en pure soie SLIPSILK"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1697882969/MyTrendIdeas/chouchous-slipsilk-xudnpc7qmgtdrvtqogxv.jpg"
                  />
                  <div className="right-description">
                    <h3>Les chouchous en pure soie SLIPSILK</h3>
                    <p>
                      Le secret de beauté des coiffeurs célèbres, des
                      dermatologues, des experts en beauté et même un mannequin
                      ou deux. Les chouchous en soie pure sont le complément
                      parfait à votre routine beauté.
                    </p>
                    <a
                      href="https://amzn.to/49dfp7Z"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>30,91 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Coffret bougies YANKEE CANDLE"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1697883797/MyTrendIdeas/lot-bougies-yankee-candle-ok0oeghfjnl6mzn3vbzb.jpg"
                  />
                  <div className="right-description">
                    <h3>Le coffret de bougies parfumées YANKEE CANDLE</h3>
                    <p>
                      Faites plaisir à un être cher avec ce coffret de trois
                      bougies joliment présentées et parfaites pour n'importe
                      quelle occasion
                    </p>
                    <a
                      href="https://amzn.to/40mnqUe"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Airpods Max"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1697884031/MyTrendIdeas/airpods-max-o6epp8zknxrhmlgfo3nl.jpg"
                  />
                  <div className="right-description">
                    <h3>Le casque Apple AirPods Max</h3>
                    <p>
                      Le casque AirPods Max d'Apple est un réducteur de bruits
                      et un amplificateur de style. Tous les influenceurs vous
                      le diront.
                    </p>
                    <a
                      href="https://amzn.to/40gMN9G"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>579,00 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Chaussons UGG Tasman"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1697884594/MyTrendIdeas/chaussons-ugg-z0blx8pmktqeb5ohzkp9.jpg"
                  />
                  <div className="right-description">
                    <h3>Les chaussons UGG AUSTRALIA Tasman</h3>
                    <p>
                      Ces chaussons de la célèbre marque sont hyper confortables
                      pour un moment détente à la maison ou à ramener pendant
                      les vacances aux skis.
                    </p>
                    <a
                      href="https://amzn.to/3MLye8T"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>A partir de 115,00 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Sac banane LULULEMON"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698086044/MyTrendIdeas/sac-lululemon-vdy46ovfxshrduvcger1.png"
                  />
                  <div className="right-description">
                    <h3>Le sac banane LULULEMON</h3>
                    <p>
                      Lululemon est une marque qui cartonne sur TikTok. Ce sac
                      tendance peut être porté en bandoulière ou autour de la
                      taille. Effet assuré.
                    </p>
                    <a
                      href="https://www.lululemon.fr/fr-fr/p/sac-de-ceinture-everywhere-1%C2%A0l/prod8900747.html?dwvar_prod8900747_color=29800"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>48,00 € | Acheter sur LULULEMON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Lampe selfie NEWMOWA"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1697885329/MyTrendIdeas/lampe-selfie-xf0srrg9ykk0zi3zmw1g.jpg"
                  />
                  <div className="right-description">
                    <h3>La lampe à selfie NEWMOWA</h3>
                    <p>
                      Cette lampe peut être clipsée sur un ordinateur ou un
                      téléphone pour des vidéos TikTok au top.
                    </p>
                    <a
                      href="https://amzn.to/3Mn1Jxx"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>32,99 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Brosse anti poil"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1697857398/MyTrendIdeas/brosse-poil-fh3667hfqfeggcm8sxzk.jpg"
                  />
                  <div className="right-description">
                    <h3>La brosse anti poil d'animaux</h3>
                    <p>
                      C'est magique. Grâce à l'effet électrostatique, ce rouleau
                      capture tous les poils de chiens et de chats qui
                      envahissent nos canapés, coussins de chaises et autres
                      espaces préférés de nos animaux de compagnie.
                    </p>
                    <a
                      href="https://amzn.to/3Qmlp5N"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>17,99 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Créole maxi"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1697823314/MyTrendIdeas/creoles-mini-bvdjkwoiyk0vej3jrs0o.jpg"
                  />
                  <div className="right-description">
                    <h3>La créole mini</h3>
                    <p>
                      Les créoles restent un indémodable qu'on a pu voir
                      énormément sur les réseaux sociaux cette année. La
                      tendance est à la mini-créole pour apporter un discret
                      point de lumière à son allure.
                    </p>
                    <a
                      href="https://amzn.to/3MpIFi6"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>14,99 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChristmasForAdults;
