import React from "react";

const ChristmasForTeens = () => {
  return (
    <>
      <div className="wrapper">
        <div className="article-info">
          <div>
            <h1>51 IDEES DE CADEAUX TENDANCES POUR LES ADOLESCENTS</h1>
            <div>
              <img
                alt="Cadeaux tendances adolescents"
                src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698341006/MyTrendIdeas/theme-cadeaux-adolescents-xa12xvt406exfqksg5jn.jpg"
              ></img>
            </div>
            <p>
              Ah ces adolescents ! Ils nous aiment mais pourtant ils nous
              demandent de les déposer le plus loin possible du lycée et
              revendent chaque année les cadeaux qu'on leur a offert avec amour.
              <br />
              Pour leur faire plaisir cette année, il y a la solution de
              l'enveloppe avec un ou plusieurs billets glissés à l'intérieur. Ou
              alors, vous pouvez choisir dans cette liste des meilleurs cadeaux
              à leur offrir, inspirée directement des tendances TikTok.
            </p>
            <span>Publié le 21 octobre 2023</span>
          </div>
        </div>
      </div>
      <div className="grey-back">
        <div className="wrapper">
          <div className="article-details">
            <div className="ideas">
              <div className="ideas-container">
                <div className="idea-container">
                  <img
                    alt="LANEIGE LIP SLEEP MASK Lot de 2"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698052237/MyTrendIdeas/laneige-lip-sleep-mask-rynlbslmraaqgcftw3f1.webp"
                  />
                  <div className="right-description">
                    <h3>Le masque de nuit pour les lèvres LANEIGE</h3>
                    <p>
                      Ce masque de nuit pour les lèvres aux fruits rouges, l'une
                      des meilleures ventes chez SEPHORA, cartonne également sur
                      les réseaux sociaux.
                    </p>
                    <a
                      href="https://www.sephora.fr/p/lip-sleeping-mask---masque-de-nuit-pour-les-levres-615228.html"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>24,00 € | Acheter sur SEPHORA</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="UGG COQUETTE"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698052837/MyTrendIdeas/chaussons-ugg-coquette-bacmgvgauenvxal4ysye.jpg"
                  />
                  <div className="right-description">
                    <h3>Les chaussons UGG Coquette</h3>
                    <p>
                      Ces chaussons en peau de mouton donneront l'impression de
                      marcher sur des nuages. Les célébrités comme Reese
                      WITHERSPOON, Cara DELEVINGNE, Kim KARDASHIAN ou encore
                      Kate HUDSON les ont définitivement adoptés.
                    </p>
                    <a
                      href="https://amzn.to/45SnbRY"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>114,55 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="APPLE WATCH SERIE 9"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698226778/MyTrendIdeas/APPLE-WATCH-SERIES-9-qfgqwxgmqg9gjeselvdn.jpg"
                  />
                  <div className="right-description">
                    <h3>La MONTRE CONNECTEE APPLE WATCH SERIES 9</h3>
                    <p>
                      Y a t'il vraiment besoin d'un commentaire pour ce produit
                      que tout le mond rève d'avoir, de 7 à 77 ans ?
                    </p>
                    <a
                      href="https://www.apple.com/fr/shop/buy-watch/apple-watch"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>A partir de 449,99 € | Acheter sur APPLE</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="INSTAX MINI LINK 2"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698081157/MyTrendIdeas/instax-mini-link-2-jkjifmh1ngpms5tmzlho.jpg"
                  />
                  <div className="right-description">
                    <h3>L'IMPRIMANTE POUR SMARTPHONE INSTAX MINI LINK 2</h3>
                    <p>
                      Cette imprimante est la petite soeur de l'Instax Mini de
                      Fujifilm. Avec elle, plus besoin d'appareil photo.
                      N'importe quel smartphone devient un mini appareil photo
                      polaroid.
                    </p>
                    <a
                      href="https://amzn.to/46OEWTx"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>109,99 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Microphone sans fil Karaoké"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698584066/MyTrendIdeas/microphone-sans-fil-kct707wmxbeg6lxlk0ax.jpg"
                  />
                  <div className="right-description">
                    <h3>Le microphone sans fil Karaoké</h3>
                    <p>
                      Grâce à ce microphone sans fil, plus besoin de se rendre
                      dans une salle pour faire du karaoké. La chambre de votre
                      ado, le salon, le garage, toutes les pièces de la maison
                      peuvent devenir un lieu ou chanter. Ce microphone peut
                      être utilisé comme micro, lecteur bluetooth, haut-parleur
                      et enregistreur.
                    </p>
                    <a
                      href="https://amzn.to/3SmiVqC"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>29,97 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="patchs Gel Yeux Rosé Serve Chilled PATCHOLOGY"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698196435/MyTrendIdeas/patch-gel-yeux-rose-Serve-Chilled-gm1q1y8gzql8qem68xyg.avif"
                  />
                  <div className="right-description">
                    <h3>
                      Les patchs Gel Yeux Rosé Serve Chilled de la marque
                      PATCHOLOGY
                    </h3>
                    <p>
                      Ces patchs à appliquer sous les yeux leur donneront bonne
                      mine le matin, surtout pour les lendemains de soirées
                      console ou Netflix.
                    </p>
                    <p>
                      A conserver au frais dans un de ces mini réfrigérateur
                      pour cosmétiques tendances !
                    </p>
                    <a
                      href="https://www.sephora.fr/p/serve-chilled---patchs-gel-yeux-rose-P10023006.html"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>14,00 € | Acheter sur SEPHORA</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Sac bandoulière Coeur matelassé COACH"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698226242/MyTrendIdeas/sac-bandouliere-coeur-coach-t4hcoks7ipajmrgwd8vg.webp"
                  />
                  <div className="right-description">
                    <h3>Le sac bandoulière Coeur matelassé COACH</h3>
                    <p>
                      Ce sac en cuir en forme de coeur de la marque COACH est à
                      porter en journée ou en soirée. Il est assez grand pour
                      accueillir leur smartphone et autres indispensables.
                    </p>
                    <p>
                      A porter en sac à main ou en bandoulière. A styliser avec
                      un foulard ou autres accessoires tendances et à poster sur
                      les réseaux.
                    </p>
                    <a
                      href="https://fr.coach.com/fr_FR/products/sac-bandoulire-heart-matelass/CE725.html?frp=CE725%20V5BLK&fromSearch=heart"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>375,00 € | Acheter sur COACH</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="LOT DE 4 AIRTAGS APPLE"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698081735/MyTrendIdeas/airtags-apple-npevrki0jke5xmzhsi2d.jpg"
                  />
                  <div className="right-description">
                    <h3>LE LOT DE 4 AIRTAGS APPLE</h3>
                    <p>
                      Pour les adolescents tête en l'air, ce lot de 4 Airtags
                      leur permettra de ne plus perdre leur sac de gym ou les
                      clés de la maison.
                    </p>
                    <a
                      href="https://amzn.to/45XBVPg"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>108,00 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Kit de fabrication de bracelets d'amitié"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698583141/MyTrendIdeas/friendship-bracelet-maker-kwyylki3qmqsicj8nswv.jpg"
                  />
                  <div className="right-description">
                    <h3>Le kit de fabrication de bracelets d'amitié</h3>
                    <p>
                      Pour les adolescents qui adorent les arts créatifs, ce kit
                      permet de créer jusqu'à 8 bracelets d'amitié à offrir à
                      ses meilleurs amis pour la vie.
                    </p>
                    <a
                      href="https://amzn.to/3QEbpGi"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>34,40 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Airpods Max"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1697884031/MyTrendIdeas/airpods-max-o6epp8zknxrhmlgfo3nl.jpg"
                  />
                  <div className="right-description">
                    <h4>Le casque Apple AirPods Max</h4>
                    <p>
                      Le casque AirPods Max d'Apple est un réducteur de bruits
                      et un amplificateur de style. Tous les influenceurs vous
                      le diront.
                    </p>
                    <a
                      href="https://amzn.to/3QELGO7"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>579,00 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Kit Découverte 4 essentiels Visage"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698221480/MyTrendIdeas/kit-visage-kylie-jenner-jhmtluf8gtxe2bfletsr.avif"
                  />
                  <div className="right-description">
                    <h3>
                      Le Kit Découverte 4 essentiels Visage de KYLIE JENNER
                    </h3>
                    <p>
                      Les fans de Kylie JENNER trouveront dans ce kit un
                      nettoyant pour visage, un tonique, une crème hydratante et
                      un sérum à la vitamine C.
                    </p>
                    <a
                      href="https://www.nocibe.fr/kylie-skin-kj-sk-set-ml-rg-x4-mini-tr-eu-20iv-skin-care-sets-1x-s247767"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>43,00 € | Acheter sur NOCIBE</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="PlayStation 5"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698580865/MyTrendIdeas/playstation-5-blodpfnzf4gmg44wogxo.jpg"
                  />
                  <div className="right-description">
                    <h3>La console de jeu PlayStation 5</h3>
                    <p>
                      Il s'agit du cadeau ultime. Vous deviendrez la personne
                      préférée à vie de l'adolescent à qui vous l'offrirez. La
                      dernière console de Sony et la plus attendue a été
                      difficile à trouver à sa sortie. Donc si vous souhaitez
                      l'offrir en cadeau, n'attendez pas la rupture de stock.
                    </p>
                    <a
                      href="https://amzn.to/3QBGmuQ"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>519,99 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="PlayStation 5"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698581144/MyTrendIdeas/coffret-dior-addict-qcp8rbfyy5nzuqxnhqtx.avif"
                  />
                  <div className="right-description">
                    <h3>Le coffret Dior Addict Le Rituel Beauté</h3>
                    <p>
                      Les adolescentes fan de la marque Dior vont adorer ces
                      produits présentés dans une pochette couture.
                    </p>
                    <p>
                      Le rituel Dior Addict inclut une routine de beauté
                      complète pour des lèvres hydratées, une peau protégée et
                      pour se parfumer de notes fraîches et florales.
                    </p>
                    <a
                      href="https://www.sephora.fr/p/dior-addict-le-rituel-beaute---baume-a-levres--gloss--serum-et-eau-de-toilette-697837.html#product-info"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>99,00 € | Acheter sur SEPHORA</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Chaise longue gonflable WEKAPO"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698203989/MyTrendIdeas/chaise-longue-gonflable-wekapo-orkkq3yglxedn0rechsy.webp"
                  />
                  <div className="right-description">
                    <h3>La chaise longue gonflable WEKAPO</h3>
                    <p>
                      Cette chaise longue tendance, disponible en 16 couleurs
                      unies ou avec motifs, égayera les après-midi pool party.
                    </p>
                    <p>
                      Le site WEKAPO livre à travers le monde alors pourquoi se
                      priver de cette idée déco tendance.
                    </p>
                    <a
                      href="http://www.wekapo.net/store/p2/WEKAPO_Air_Lounger_purple.html"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>39,99 $US | Acheter sur WEKAPO</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="LOT DE 4 CABLES DE CHARGE RAPIDE POUR IPHONE"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698083078/MyTrendIdeas/cable-coude-iphone-tapxrhrrkvulldjwk1w3.jpg"
                  />
                  <div className="right-description">
                    <h3>LE LOT DE 4 CABLES DE CHARGE RAPIDE POUR IPHONE</h3>
                    <p>
                      Ces câbles de charge pour IPHONE sont pratiques pour jouer
                      à des jeux, regarder des vidéos, diffuser ou encore lire
                      des livres électroniques lors de la charge. Petit plus,
                      leurs couleurs pastels et leur forme coudé à l'extréémité
                      qui évite d'abimer le cable.
                    </p>
                    <a
                      href="https://amzn.to/3Qhx5qf"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>14,99 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Tablette d'écriture LCD"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698583517/MyTrendIdeas/tablette-ecriture-lcd-p4lc7lbzvjzir83oogor.jpg"
                  />
                  <div className="right-description">
                    <h3>La tablette d'écriture LCD</h3>
                    <p>
                      Cette tablette de 12 pouces est super pour dessiner ou
                      écrire des messages. Léger, il se transportera facilement
                      pour pouvoir exprimer sa créativité peut importe
                      l'endroit.s
                    </p>
                    <a
                      href="https://amzn.to/3Snb7Fc"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>14,99 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="VIDEOPROJECTEUR PORTABLE"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698084419/MyTrendIdeas/videoprojecteur-portable-y4sx7rttpiitkqs3lbap.jpg"
                  />
                  <div className="right-description">
                    <h3>LE MINI PROJECTEUR PORTABLE</h3>
                    <p>
                      Ce mini projecteur, compatible iOS et Android, est idéal
                      aussi bien pour une séance de jeux vidéos que pour
                      bingewatcher des séries Netflix en mode grand écran.
                    </p>
                    <a
                      href="https://amzn.to/47e8Yj4"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button> 119,99 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="kindle AMAZON"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698195517/MyTrendIdeas/kindle-amazon-oxatkmjqpa59ylqoe7q2.jpg"
                  />
                  <div className="right-description">
                    <h3>La tablette Kindle d'AMAZON</h3>
                    <p>
                      Cette tablette est la plus légère et compacte de la gamme
                      avec son écran haute résolution. Ce version est sans
                      publicités. Elle ravira les adolescent(e)s fans de mangas
                      ou le livre We were liars, un best seller chez les
                      adolescents.
                    </p>
                    <a
                      href="https://amzn.to/3sgWAjF"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button> 109,99 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="NINTENDO SWITCH"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698223339/MyTrendIdeas/nintendo-switch-zdvmrzhjz4j0ctqpwtmb.jpg"
                  />
                  <div className="right-description">
                    <h3>La console NINTENDO SWITCH OLED Edition Mario</h3>
                    <p>
                      Seul, en famille ou entre amis. Tout le monde pourra en
                      profiter lors de sessions de Mario Kart ou Zelda ou encore
                      FIFA interminables.
                    </p>
                    <a
                      href="https://amzn.to/3tW3QC5"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>319,99 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Chaise de gamer de GTPLAYER"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698222858/MyTrendIdeas/chaise-gamer-gtplayer-ldrffugmys2qi7alplzi.webp"
                  />
                  <div className="right-description">
                    <h3>La chaise de gamer de GTPLAYER</h3>
                    <p>"Parfait pour les jeux et la vie".</p>
                    <p>
                      Cette chaise avec son oreiller lombaire de massage et son
                      repose-pieds est le cadeau idéal pour les ados gamers.
                      Tellement confortable qu'ils ne le quitteront plus.
                    </p>
                    <a
                      href="https://fr.gtplayer.com/collections/chaise-de-gaming/products/serie-repose-pieds-gt800a"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>129,99 € | Acheter sur GTPLAYER</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Sac banane LULULEMON"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698086044/MyTrendIdeas/sac-lululemon-vdy46ovfxshrduvcger1.png"
                  />
                  <div className="right-description">
                    <h3>Le sac banane LULULEMON</h3>
                    <p>
                      Lululemon est une marque qui cartonne sur TikTok. Ce sac
                      tendance peut être porté en bandoulière ou autour de la
                      taille. Effet assuré.
                    </p>
                    <a
                      href="https://www.lululemon.fr/fr-fr/p/sac-de-ceinture-everywhere-1%C2%A0l/prod8900747.html?dwvar_prod8900747_color=29800"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>48,00 € | Acheter sur LULULEMON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Stylo 3D"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698581664/MyTrendIdeas/stylo-3D-ftzgll1rj7qkucpuyl50.jpg"
                  />
                  <div className="right-description">
                    <h3>Le stylo 3D</h3>
                    <p>
                      Pour les adolescents qui adorent dessiner, ce stylo permet
                      de reproduire leurs meilleures créations. Il peut
                      également utilisé pour réaliser des cadeaux fait-main ou
                      des décorations voire des petites pièces de rechange.
                    </p>
                    <a
                      href="https://amzn.to/40gKuDy"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>59,99 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Twist & Shout Vegan Shacket"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698197443/MyTrendIdeas/Twist-Shout-Vegan-Free-People-vvk6gjva0nra8ej4kylu.jpg"
                  />
                  <div className="right-description">
                    <h3>Le Twist & Shout Vegan Shacket de FREE PEOPLE</h3>
                    <p>
                      Cette veste à frange est un intemporel qui donne un look
                      des années 70.
                    </p>
                    <a
                      href="https://www.freepeople.com/france/shop/twist-and-shout-vegan-shacket/?color=022&type=REGULAR&quantity=1"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>138,00 € | Acheter sur FREE PEOPLE</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Mini batterie externe ultra compacte"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698582877/MyTrendIdeas/mini-batterie-externe-pyqp1mebeqt8oeicni2i.jpg"
                  />
                  <div className="right-description">
                    <h3>La mini batterie externe ultra compacte</h3>
                    <p>
                      Fini les problèmes de batterie vidées par vos ados qui ont
                      passé la pause déjeuner à regarder des réels sur TikTok et
                      ne peuvent plus vous appeler à la fin de la journée. Avec
                      cette batterie compacte qui se glisse dans la poche, ils
                      peuvent recharger leur portable à l'extérieur.
                    </p>
                    <p>
                      Cette mini batterie est compatible avec tous les appareils
                      iOS et Android.
                    </p>
                    <a
                      href="https://amzn.to/498c6ii"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>17,49 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Leggings LULULEMON"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698227722/MyTrendIdeas/legging-taille-haute-lululemon-mhah2ye2icagjdluu0nn.webp"
                  />
                  <div className="right-description">
                    <h3>Le legging LULULEMON Align taille haute</h3>
                    <p>
                      On ne présente plus la marque LULULEMON ni le phénomène
                      leggings sur les réseaux sociaux. Les 2 fusionnées donnent
                      ce legging taille haute ultra tendance.
                    </p>
                    <a
                      href="https://www.lululemon.fr/fr-fr/p/legging-lululemon-align%E2%84%A2-cotele-taille-haute-64%C2%A0cm/prod11240313.html?dwvar_prod11240313_color=0001"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>
                        A partir de 49,00 € | Acheter sur LULULEMON
                      </button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Boudin boucleur sans chaleur"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698085112/MyTrendIdeas/boudin-boucle-cheveux-gnfqahynypzezyok2dlv.jpg"
                  />
                  <div className="right-description">
                    <h3>Le boudin pour boucler les cheveux sans chaleur</h3>
                    <p>
                      Il suffit d'enrouler ses cheveux autour du boucleur sans
                      chaleur avant d'aller se coucher. On obtient facilement
                      des boucles naturelles incroyables.
                    </p>
                    <a
                      href="https://amzn.to/40gSep4"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>12,99 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Tourne disque Voyager CROSLEY"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698205428/MyTrendIdeas/tourne-disque-voyager-crosley-un2dsszu8ip0wg6opmbd.jpg"
                  />
                  <div className="right-description">
                    <h3>Le tourne-disque Voyager de CROSLEY</h3>
                    <p>
                      Pourquoi pas ce tourne-disque de la marque CROSLEY
                      accompagné d'un vinyle de Taylor SWIFT pour les faire
                      voyager dans le temps.
                    </p>
                    <a
                      href="https://amzn.to/40h2VZ0"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>110,00 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Hoodie SKIMS"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698110215/MyTrendIdeas/hoodie-skims-zouubgtyeghjnpnh9enq.jpg"
                  />
                  <div className="right-description">
                    <h3>Le sweat à capuche SKIMS</h3>
                    <p>
                      SKIMS est une marque américaine fondée par Kim KARDASHIAN.
                      La marque pratique des tailles inclusives. Elle propose
                      des vêtements et sous-vêtements cocooning ultra-doux qui
                      vont du XXS au XXXXXL5.
                    </p>
                    <p>
                      Bien sûr, la famille KARDASHIAN/JENNER s'est fait un
                      devoir de rendre viral les produits de la marque sur les
                      réseaux sociaux.
                    </p>
                    <a
                      href="https://www.skims-france.fr/products/skims-coton-fleece-zip-up-hoodie-noir-grise-158rwdqug-p-146.html"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>49,03 € | Acheter sur SKIMS FRANCE</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Bandeau SPA"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698112575/MyTrendIdeas/bandeau-spa-bgvfgwjhi9xboousvk1o.jpg"
                  />
                  <div className="right-description">
                    <h3>Le bandeau doux en éponge</h3>
                    <p>
                      Ce bandeau au design mignon séduira toutes les
                      adolescentes qui aiment faire des vidéos de make-up à
                      poster sur les réseaux.
                    </p>
                    <a
                      href="https://amzn.to/40q0seL"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>54,16 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Néon prénom"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698224271/MyTrendIdeas/neon-personnalisable-q7kg4fb81f3pvcbbzlzv.jpg"
                  />
                  <div className="right-description">
                    <h3>Le néon personnalisable</h3>
                    <p>
                      Ce néon LED personnalisé complètera la déco de la chambre
                      de vos adolescents. On peut choisir aussi bien la couleur
                      que la taille, la police et le nombre de lignes. Laissez
                      faire votre créativité.
                    </p>
                    <a
                      href="https://amzn.to/3Mnjahb"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>35,99 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Lampe lune 3D"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698579232/MyTrendIdeas/lampe-lune-3D-wblhsgnnhekka4pyb55k.jpg"
                  />
                  <div className="right-description">
                    <h3>La lampe 3D Veilleuse à LED</h3>
                    <p>
                      Cette lampe représente la lune avec son relief accidenté
                      grâce à la 3D. Il offre une palette de couleurs et de
                      niveaux de luminosité, ce qui permettra à chacun de
                      l'ajuster à ses goûts.
                    </p>
                    <a
                      href="https://amzn.to/3Qh3htT"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>18,99 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Gourde Stanley Quencher"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698116505/MyTrendIdeas/gourde-stanley-quencher-bwhsxhbhuql9pembroat.webp"
                  />
                  <div className="right-description">
                    <h3>Le gobelet Quencher H2.O FlowState de STANLEY</h3>
                    <p>
                      Les adolescents se l'arrachent pour les personnaliser avec
                      des stickers et les revendre sur les boutiques de DIY.
                    </p>
                    <a
                      href="https://amzn.to/49a3pUV"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>82,63 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Le coffret Soin Corps Bum Bum Body Obsession SOL DE JANEIRO"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698118938/MyTrendIdeas/kit-bum-bum-body-obsession-nzvcvdeca4fw5preoosy.webp"
                  />
                  <div className="right-description">
                    <h3>Le coffret Soin Corps Bum Bum Body Obsession</h3>
                    <p>
                      La crème et la brume parfumée de la marque SOL DE JANEIRO
                      sont tagguées HOT ON SOCIAL par SEPHORA et ce m'est pas
                      pour rien car ces produits sont devenus rapidement viraux.
                    </p>
                    <p>
                      Ce coffret aux couleurs tropicales nous fait voyager. Il
                      contient une crème, une eau de parfum, un gel douche, un
                      exfoliant et une huile pour le corps.
                    </p>
                    <a
                      href="https://www.sephora.fr/p/bum-bum-body-obsession---coffret-soin-corps-693797.html#product-info"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>69,00 € | Acheter sur SEPHORA</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Sac à dos Fjallraven Mixte Kånken"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698134129/MyTrendIdeas/sac-Fjallraven-MixteKa%CC%8Anken-nmqdtr5wlncr7i65bwbu.jpg"
                  />
                  <div className="right-description">
                    <h3>Le sac à dos Kånken de FJALLRAVEN</h3>
                    <p>
                      La marque suédoise FJALLRAVEN propose ce modèle de sac à
                      dos idéal pour les adolescents en 22 couleurs différentes.
                      De quoi satisfaire tous les goûts.
                    </p>
                    <a
                      href="https://amzn.to/3s6GJ7k"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>80,83 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Table de chevet portable"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1697864282/MyTrendIdeas/table-chevet-portable-isuvv5zwbwb5nat1h2or.jpg"
                  />
                  <div className="right-description">
                    <h3>La table de chevet pliante</h3>
                    <p>
                      Avec cette table de chevet pratique, votre iPad, votre
                      téléphone portable, vos livres, vos réveils, vos boissons
                      ou tout autre objet que vous pouvez emporter au lit est à
                      une longueur de bras.
                    </p>
                    <a
                      href="https://amzn.to/3FEWOUQ"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>26,99 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Juicy Tubes LANCOME"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698201376/MyTrendIdeas/juicy-tubes-lancome-whcciytaoah6d3jnyyfo.jpg"
                  />
                  <div className="right-description">
                    <h3>Le gloss hydratant Juicy Tubes de LANCÔME</h3>
                    <p>
                      Ce gloss emblématique de la marque LANCÔME séduira les
                      adolescents attirés par le "rétro" des années 2000.
                    </p>
                    <a
                      href="https://www.lancome.fr/nouveautes/bestsellers/juicy-tubes-original/115012-LAC.html"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>19,00 € | Acheter sur LANCÔME</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Sony SRS-XB100 Bluetooth Speaker"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698134667/MyTrendIdeas/enceinte-sony-z4cdgliazxcurh5ibeig.jpg"
                  />
                  <div className="right-description">
                    <h3>L'enceinte Sony SRS-XB100'</h3>
                    <p>
                      Cette enceinte super compacte produit un son puissant avec
                      des basses profondes. Il plaira à tous les amoureux de
                      musique.
                    </p>
                    <a
                      href="https://amzn.to/45TwdOn"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>64,90 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Karaoké complet de JYX"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698202109/MyTrendIdeas/karaoke-complet-jyx-ugcdc0ojxcknnpkcn3if.jpg"
                  />
                  <div className="right-description">
                    <h3>Le Karaoké complet transportable de JYX</h3>
                    <p>
                      Si votre ado est tombé dans la tendance drama coréen et
                      karaokés en salle, va à tous les concerts de Taylor SWIFT,
                      BEYONCE ou RIHANNA, alors ce karaoké complet est fait pour
                      lui.
                    </p>
                    <p>
                      Cette boîte de nuit, équipée de lumières DJ, peut être
                      emporté partout facilement.
                    </p>
                    <a
                      href="https://amzn.to/45XCloO"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>148,99 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Mirroir en forme de coeur URBAN OUTFITTERS"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698136506/MyTrendIdeas/miroir-coeur-urban-outfitters-kis165zyg2ookule0l1u.png"
                  />
                  <div className="right-description">
                    <h3>Le Miroir LED en forme de cœur URBAN OUTFITTERS</h3>
                    <p>
                      Ce miroir à LED en forme de coeur est à rajouter dans tous
                      vos coins dressing.
                    </p>
                    <a
                      href="https://www.urbanoutfitters.com/fr-fr/shop/led-heart-tabletop-mirror?category=SEARCHRESULTS&color=055&searchparams=q%3Dmiroir%2520coeur&type=REGULAR&size=ONE%20SIZE&quantity=1"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>22,00 € | Acheter sur URBAN OUTFITTERS</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Mini réfrigérateur à cosmétiques SKINCARE"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1697854293/MyTrendIdeas/mini-refrigerateur-tsleosczyymftyrlcuxf.jpg"
                  />
                  <div className="right-description">
                    <h3>Le mini réfrigérateur à cosmétiques SKINCARE</h3>
                    <p>
                      Ce mini réfrigérateur conservera leurs cosmétiques
                      préférées au frais. Cadeau COOL, n'est ce pas ?
                    </p>
                    <a
                      href="https://amzn.to/3sgjI1O"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>59,99 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Lot de 2 ruban LED DALATTIN"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698225136/MyTrendIdeas/ruban-led-dalattin-remectsxnjy0xry6kap1.jpg"
                  />
                  <div className="right-description">
                    <h3>Le lot de 2 rubans LED intelligents DALATTIN</h3>
                    <p>
                      Ces bandes lumineuses de 30m avec 16 millions de couleurs
                      se contrôlent à distance via une application.
                      Intelligentes, elles dansent au rythme de la musique et
                      s'allument ou changent de couleur par commande vocale.
                    </p>
                    <p>
                      Ambiance boîte de nuit assurée lors de la prochaine soirée
                      pyjama.
                    </p>
                    <a
                      href="https://amzn.to/3sgjQOQ"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>88,79 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Marqueurs pour tatouage temporaire"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698579796/MyTrendIdeas/stylos-tatouage-temporaire-vixlfziekhdkjbchrafo.jpg"
                  />
                  <div className="right-description">
                    <h3>Le lot de 2 rubans LED intelligents DALATTIN</h3>
                    <p>
                      Pour les adolescents qui veulent se faire tatouer et les
                      parents qui sont contre, ce cadeau est le meilleur
                      compromis. Ce kit contient 8 stylos de couleurs et 3
                      pochoirs même si on peut faire appel à sa créativité et
                      créer son propre motif.. L'encre tient 2 jours.
                    </p>
                    <a
                      href="https://amzn.to/46RQSno"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>31,10 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="bottes Classic Ultra Mini UGG"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698203042/MyTrendIdeas/ugg-classic-mini-osmxlqcj9jkv7vlqenoa.jpg"
                  />
                  <div className="right-description">
                    <h3>Les bottes Classic Ultra Mini UGG</h3>
                    <p>
                      La paire de bottes Classic de la marque UGG, avec sa
                      doublure chaude, est parfaite pour les journées enneigées.
                    </p>
                    <a
                      href="https://amzn.to/3MmGs70"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>189,00 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Sac à dos BOREALIS SLING de NORTH FACE"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698138060/MyTrendIdeas/sac-a-dos-borealis-north-face-tlg51rpmcukqw8tvhzbd.webp"
                  />
                  <div className="right-description">
                    <h3>Le Sac à dos BOREALIS SLING de THE NORTH FACE</h3>
                    <p>
                      Les sportifs adorent ce sac qui est un des best seller de
                      la marque THE NORTH FACE. Il est doté de bretelles
                      rembourrées, d'une poche latérale pour y glisser une
                      bouteille d'eau et une pochette dédiée aux tablettes.
                    </p>
                    <a
                      href="https://www.thenorthface.fr/shop/fr/tnf-fr/sac-a-dos-sling-borealis-52up"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>55,00 € | Acheter sur THE NORTH FACE</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Sèche-cheveu volumisant REVLON"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1697856732/MyTrendIdeas/seche-cheveu-volumisant-zzt9q7r1rrxwd54ueapn.jpg"
                  />
                  <div className="right-description">
                    <h3>Le sèche-cheveu volumisant REVLON</h3>
                    <p>
                      Grâce au sèche-cheveu volumisant de REVLON célèbre sur
                      TikTok, les ados ont devenir des pros du brushing.
                    </p>
                    <a
                      href="https://amzn.to/3QEMCSE"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>37,51 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Haut-parleurs de bureau REDRAGON"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698225784/MyTrendIdeas/haut-parleurs-redragon-gxrqtoq55ntnzdknnklg.jpg"
                  />
                  <div className="right-description">
                    <h3>Les hauts-parleurs de bureau REDRAGON</h3>
                    <p>
                      Ces hauts-parleurs offrent une très bonne qualité de son
                      mais son design est également attractif avec sa barre
                      d'éclairage.
                    </p>
                    <a
                      href="https://amzn.to/45Zbf0t"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>45,50 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Batterie externe pour smartphones"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698139181/MyTrendIdeas/batterie-externe-anker-o5qyqiu5ithnmkj3hotm.jpg"
                  />
                  <div className="right-description">
                    <h3>La batterie externe pour smartphones ANKER</h3>
                    <p>
                      Cette batterie externe, compatible iOS et ANDROID, évitera
                      à vos enfants de se retrouver à plat.
                    </p>
                    <a
                      href="https://amzn.to/45YmrKW"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>39,99 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Mini clavier et souris sans fil"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698140130/MyTrendIdeas/mini-clavier-souris-sans-fil-cwcy2bxclhbombanev9j.jpg"
                  />
                  <div className="right-description">
                    <h3>
                      L'ensemble mini clavier et souris sans fil PERIXX PERIDUO
                    </h3>
                    <p>
                      Joli clavier et souris compacts dans les tons rose, violet
                      et blanc. Idéal pour les gamers ou les blogueurs.
                    </p>
                    <a
                      href="https://amzn.to/3FFeTlK"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>34,99 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="DIOR LIP GLOW OIL"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698198031/MyTrendIdeas/lip-glow-oil-lsemn0lujfqixmy5z7nz.avif"
                  />
                  <div className="right-description">
                    <h3>L'huile à lèvres colorée Dior Addict</h3>
                    <p>
                      Elles vont adorer cette huile hydratante proposée en hui
                      teintes qui est devenue virale sur TikTok.
                    </p>
                    <a
                      href="https://www.sephora.fr/p/dior-lip-glow-oil---huile-a-levres-brillante-nourrissante-555352.html"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>47,00 € | Acheter sur SEPHORA</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="baskets Nike Air Force 1 '07"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698140777/MyTrendIdeas/nike-air-force-hf1p17sijpdslohmx8ju.webp"
                  />
                  <div className="right-description">
                    <h3>La paire de baskets Nike Air Force 1 '07 pour homme</h3>
                    <p>
                      Cette paire de basket de la marque NIKE est visible dans
                      toutes les cours de récréation aussi bien que sur les
                      photos des stars des réseaux sociaux.
                    </p>
                    <a
                      href="https://res.cloudinary.com/dug8eeke1/image/upload/v1698140777/MyTrendIdeas/nike-air-force-hf1p17sijpdslohmx8ju.webp"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>119,99 € | Acheter sur NIKE</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="LAMPE DE LECTURE DE COU GLOCUSENT"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698053700/MyTrendIdeas/lampe-de-cou-n5bhuzyycgc3gl7xcnab.jpg"
                  />
                  <div className="right-description">
                    <h3>La lampe de lecture de cou GLOCUSENT</h3>
                    <p>
                      Les adolescents en colocation qui aiment lire un livre au
                      lit ou ceux qui préfèrent jouer à la console sous la
                      couette apprécieront cette veilleuse de lecture.
                    </p>
                    <a
                      href="https://amzn.to/3SlHu6T"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>30,99 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChristmasForTeens;
