import React from "react";

const ChristmasForKids = () => {
  return (
    <>
      <div className="wrapper">
        <div className="article-info">
          <div>
            <h1>32 IDEES DE JOUETS TENDANCES A OFFRIR A NOEL</h1>
            <p>
              La fête d'Halloween est à peine passée, la douce voix de Mariah
              Carey commence à susurrer à notre oreille son indémodable chanson
              "All I want for Christmas", les feuilletons de Noel envahissent
              nos écrans. On y est, les préparatifs des fêtes de fin d'année
              commencent. A vos marques, prêt, partez ... pour shopper les
              cadeaux tendances qui mettront des paillettes dans les yeux de nos
              enfants.
            </p>
            <p className="theme-credit">Publié le 20 octobre 2023</p>
            <img
              alt="idées jouets Noel"
              src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698630375/MyTrendIdeas/theme-jouets-lot6qoe1txie9mjyeqkc.png"
            ></img>
          </div>
        </div>
      </div>
      <div className="grey-back">
        <div className="wrapper">
          <div className="article-details">
            <div className="ideas">
              <h2>L'UNIVERS DE GABBY ET LA MAISON MAGIQUE</h2>
              <div className="ideas-container">
                <div className="idea-container">
                  <img
                    alt="Jouets Noël 2023"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1697887828/MyTrendIdeas/poupee-gabby-brogxojkvqdta1qm9plu.jpg"
                  />
                  <div className="right-description">
                    <h3>Poupée Gabby 20cm</h3>
                    <p>
                      Avec ses détails réalistes, cette poupée à l'effigie de
                      Gabby de 20 cm accompagnera les enfants partout. Ils
                      développeront leur créativité en recréant les scénarii de
                      la série animée.
                    </p>
                    <span>3 ans et +</span>
                    <a
                      href="https://amzn.to/3Sm6ElX"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>19,99 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Maison magique de Gabby"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1697887941/MyTrendIdeas/maison-magique-gabby-x8ak82ofgssp04n5ibhc.jpg"
                  />
                  <div className="right-description">
                    <h3>La maison magique de Gabby</h3>
                    <p>
                      Avec cette réplique géante (plus de 60 cm) et intéractive
                      (plusieurs effets sonores) de la maison magique, vos
                      enfants seront immergés dans l'univers de Gabby et prendre
                      le chat-scenseur pour aller rendre visite aux gabbychats.
                    </p>
                    <span>3 ans et +</span>
                    <a
                      href="https://amzn.to/3ShhWbh"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>79,99 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Les oreilles de Gabby"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1697888175/MyTrendIdeas/oreilles-gaby-oaih9lbqyqg9dk8rytxe.jpg"
                  />
                  <div className="right-description">
                    <h3>Les oreilles de Gabby</h3>
                    <p>
                      Ce jouet possède plus de 10 sons, répliques et chansons de
                      la série. C'est le moment de rapetisser pour se rendre
                      dans la maison magique ! Un pincement à gauche et puis un
                      pincement à droite ...
                    </p>
                    <span>3 ans et +</span>
                    <a
                      href="https://amzn.to/3SsQeYW"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>18,99 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Lego Gabby"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1697888303/MyTrendIdeas/lego-gabby-ffmhk8berkmbktkhh8jd.jpg"
                  />
                  <div className="right-description">
                    <h3>Le jouet LEGO La fête au jardin de la fée Minette</h3>
                    <p>
                      Ce jouet de construction LEGO Gabby et la Maison Magique
                      comprend une minifigurine Gabby ainsi que les figurines
                      Pandy et Fée Minette, le chat de Gabby et la Maison
                      Magique. Il y a un guide de construction d'histoires
                      illustrées en couleurs afin d'aider les petits à se créer
                      leur univers.
                    </p>
                    <span>4 ans et +</span>
                    <a
                      href="https://amzn.to/3Sm3ZZr"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>22,99 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
              </div>
              <h2>LA PAT PATROUILLE - LE FILM</h2>
              <div className="ideas-container">
                <div className="idea-container">
                  <img
                    alt="Véhicule Chase LA SUPER PATROUILLE"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698461019/MyTrendIdeas/vehicule-chase-supper-patrouille-kihqqaqxge8rqo4enati.jpg"
                  />
                  <div className="right-description">
                    <h3>
                      LE VÉHICULE OFFICIEL DE CHASE DE LA SUPER PATROUILLE
                    </h3>
                    <p>
                      Le film vient à peine de sortir au cinéma et c'est déjà un
                      succès planétaire. Le véhicule 2 en 1 de Chase, réplique
                      officielle de la voiture du film fera plaisir à nos petits
                      fans.
                    </p>
                    <span>3 ans et +</span>
                    <a
                      href="https://amzn.to/40jHgzl"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>59,99 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Véhicule Stella LA SUPER PATROUILLE"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698461434/MyTrendIdeas/vehicule-stella-super-patrouille-mupkdbni9cerdj2rz2jj.jpg"
                  />
                  <div className="right-description">
                    <h3>
                      LE VÉHICULE OFFICIEL DE STELLA DE LA SUPER PATROUILLE
                    </h3>
                    <p>
                      Ce véhicule est la réplique de la voiture de Stella de la
                      SUPER PATROUILLE. Un bon début pour une collection de tous
                      les chiots du film et leur véhicules.
                    </p>
                    <span>3 ans et +</span>
                    <a
                      href="https://amzn.to/45OVE3z"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>59,99 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Appareil photo PAT PATROUILLE"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698461617/MyTrendIdeas/appareil-photo-pat-patrouille-lwdksu9w8h41ak7x1xag.jpg"
                  />
                  <div className="right-description">
                    <h3>L'appareil à photo instantanée</h3>
                    <p>
                      Cet appareil photo à impression instantanée fera plaisir
                      aux petits notamment grâce à ses filtres et autocollants
                      issus du dessin animé.
                    </p>
                    <span>4 ans et +</span>
                    <a
                      href="https://amzn.to/40h58DM"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>69,90 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Jeux de société PAT PATROUILLE"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698461970/MyTrendIdeas/jeux-societe-pat-patrouille-rgwwqtoc9rdxwvxc7vp4.jpg"
                  />
                  <div className="right-description">
                    <h3>
                      Les jeux de société PAT PATROUILLE de la marque ASMODEE
                    </h3>
                    <p>
                      Pour un moment de convivialité avec la famille ou les
                      meilleurs amis avec ses jeux de société PAT PATROUILLE.
                    </p>
                    <span>6 ans et +</span>

                    <a
                      href="https://amzn.to/3u0srW6"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>15,99 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
              </div>
              <h2>LE MONDE DE BARBIE</h2>
              <div className="ideas-container">
                <div className="idea-container">
                  <img
                    alt="Poupée Barbie"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1697888653/MyTrendIdeas/poupee-barbie-e3ha6ixzmwmm3r5poiwp.jpg"
                  />
                  <div className="right-description">
                    <h3>Barbie le film : Barbie en robe Vichy</h3>
                    <p>
                      Cette poupée est inspirée d'un des looks emblématiques du
                      personnage du film. Elle porte une jolie robe vichy rose,
                      un collier de marguerites et des escarpins pastels. Prête
                      pour une sortie en ville avec son ami Ken.
                    </p>
                    <span>3 ans et +</span>
                    <a
                      href="https://amzn.to/40g8hDR"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>47,50 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="La maison rêvée de Barbie"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1697888935/MyTrendIdeas/maison-revee-Barbie-vkl1un9dxhmybkls2rm5.jpg"
                  />
                  <div className="right-description">
                    <h3>La maison de rêve de Barbie</h3>
                    <p>
                      Cette maison de poupée de luxe possède le toboggan le plus
                      grand à ce jour de la gamme Maison de Rêve de Barbie, avec
                      un design en spirale sur trois niveaux pour s’amuser toute
                      la journée au soleil. Elle est dotée de plus de 75
                      accessoires, de quoi s'amuser du matin au soir.
                    </p>
                    <span>3 ans et +</span>
                    <a
                      href="https://amzn.to/49hZeX6"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>239,99 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Ken"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1697889189/MyTrendIdeas/ken-f8dijy57glwdbcyawhql.jpg"
                  />
                  <div className="right-description">
                    <h3>Barbie le film : Ken</h3>
                    <p>
                      La poupée Ken est parée pour aller faire du surf avec vos
                      enfants à la mer ou dans la salle de bain. Peu importe
                      l'endroit, amusement garanti.
                    </p>
                    <span>3 ans et +</span>
                    <a
                      href="https://amzn.to/49jpP5V"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>42,99 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Barbie Fashionistas"
                    src="https://amzn.to/3tUTcvk"
                  />
                  <div className="right-description">
                    <h3>Le dressing de rêve de Barbie</h3>
                    <p>
                      La gamme Fashionistas a été créée afin de refleter la
                      façon dont les enfants voient le monde aujourd'hui. Le
                      dressing de Rêve est fourni avec tous les accessoires
                      tendances (bijoux, sacs, chaussures) et permettront à vos
                      anges de jouer aux modeuses.
                    </p>
                    <span>3 ans et +</span>
                    <a
                      href="https://www.amazon.fr/Barbie-Fashionistas-Dressing-accessoires-GBK12/dp/B07NGX7TDN?ref_=ast_sto_dp&th=1"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>39,96 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
              </div>
              <h2>LES JEUX D'IMITATION MELISSA & DOUG</h2>
              <div className="ideas-container">
                <div className="idea-container">
                  <img
                    alt="Kit barbier MELISSA & DOUG"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1697791086/MyTrendIdeas/kit-barbier-kye34t2fkcykbzvin8hp.jpg"
                  />
                  <div className="right-description">
                    <h3>Le Kit du Barbier MELISSA & DOUG</h3>
                    <p>
                      Avec le kit du barbier, votre enfant deviendra un vrai
                      professionnel. Il jouera à brosser, peigner et tondre
                      grâce aux multiples accessoires.
                    </p>
                    <span>3 ans et +</span>
                    <a
                      href="https://amzn.to/3QjbOwr"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>38,95 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Kit vétérinaire MELISSA & DOUG"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1697890254/MyTrendIdeas/kit-veterinaire-o5gzwu0mmi1en8ovsaox.jpg"
                  />
                  <div className="right-description">
                    <h3>Le Kit du Vétérinaire MELISSA & DOUG</h3>
                    <p>
                      Vos enfants adorent les animaux ? Pourquoi pas les
                      préparer au métier de vétérinaire avec ce kit complet de
                      24 pièces, ainsi qu'un chat et un chien en peluche.
                    </p>
                    <span>3 ans et +</span>
                    <a
                      href="https://amzn.to/40kkEyv"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>37,19 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Kit dentiste MELISSA & DOUG"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1697890338/MyTrendIdeas/kit-dentiste-srh2c5fafps9uf3l1xfj.jpg"
                  />
                  <div className="right-description">
                    <h3>Le Kit du Dentiste MELISSA & DOUG</h3>
                    <p>
                      Pour que les visites chez le dentiste ne deviennent pas
                      une phobie chez les petits, pourquoi pas les laisser jouer
                      aux apprentis dentistes et prendre comme cobaye leur papa,
                      leur maman ou leur tata préférée !
                    </p>
                    <span>3 ans et +</span>
                    <a
                      href="https://amzn.to/3skeJwP"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>40,95 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Kit du docteur MELISSA & DOUG"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1697890698/MyTrendIdeas/kit-docteur-q4tk11mcw1dfkh4bbks5.jpg"
                  />
                  <div className="right-description">
                    <h3>Le Kit du Docteur MELISSA & DOUG</h3>
                    <p>
                      Thermomètre, , stéthoscope, seringue, tensiomètre... Avec
                      ce kit, ce sera au tour des enfants de soigner les petits
                      bobos des grands.
                    </p>
                    <span>3 ans et +</span>
                    <a
                      href="https://amzn.to/3FJgJSk"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>41,99 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
              </div>
              <h2>LES JOUETS D'EVEIL FISHER PRICE</h2>
              <div className="ideas-container">
                <div className="idea-container">
                  <img
                    alt="Table de mixage Fisher Price"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1697793616/MyTrendIdeas/table-dj-fp-y9ronh87pbernvqtu15a.jpg"
                  />
                  <div className="right-description">
                    <h3>La table d'activités de DJ</h3>
                    <p>
                      Ce jouet d’éveil interactif est une table de mixage qui
                      comprend un piano avec 3 modes de jeu, 12 activités pour
                      renforcer la motricité fine et 3 niveaux d’apprentissage
                      Éveil Progressif pour accompagner bébé à mesure qu’il
                      grandit. Parfait pour faire de votre progéniture les
                      Martin Garrix ou Ofenbach de la prochaine génération.
                    </p>
                    <span>A partir de 6 mois</span>
                    <a
                      href="https://amzn.to/3FCRIrZ"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>44,99 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Voiture de Barbie"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1697793983/MyTrendIdeas/coussin-lama-fp-thbp5qcafarluqc9rmto.jpg"
                  />
                  <div className="right-description">
                    <h3>Le coussin d'éveil Lama Fisher Price</h3>
                    <p>
                      Ce coussin d'appui en peluche est en forme de lama, animal
                      tendance après la licorne chez les petits. Il est composé
                      de 3 jouets : un anneau de dentition, un miroir et un
                      hochet pastèque pour aider bébé à développer ses sens.
                    </p>
                    <span>Dès la naissance</span>
                    <a
                      href="https://amzn.to/3sfFFhj"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>35,99 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Tapis d'éveil Fisher Price"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1697794430/MyTrendIdeas/tapis-eveil-fp-xj2mw5vi7frxkddyc06f.jpg"
                  />
                  <div className="right-description">
                    <h3>Le tapis musical d'éveil de la Jungle</h3>
                    <p>
                      Ce tapis est composé de plusieurs jouets afin de stimuler
                      la motricité de bébé. Le toucan joue de la musique chaque
                      fois qu'il le fait bouger. <br />
                    </p>
                    <span>Dès la naissance</span>
                    <a
                      href="https://amzn.to/3SpwYf1"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>59,49 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Linkimals Matthieu le Paresseux"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1697795105/MyTrendIdeas/linkimals-paresseux-yzikbqwpioxa5uiwheiy.jpg"
                  />
                  <div className="right-description">
                    <h3>Linkimals Mathieu le Paresseux</h3>
                    <p>
                      Vous connaissez la collection Linkimals de Fisher Price ?
                      Il y a le paresseux mais aussi la chouette, le koala, la
                      baleine. Placés l'un à côté de l'autre, ils agissent tous
                      ensemble
                    </p>
                    <span>A partir de 9 mois</span>
                    <a
                      href="https://amzn.to/3SmzjYb"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>37,99 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
              </div>
              <h2>LA COLLECTION LITTLE LIVE PETS</h2>
              <div className="ideas-container">
                <div className="idea-container">
                  <img
                    alt="Poisson Little Live Pets"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1697800261/MyTrendIdeas/poisson-llp-ev7gc3akcy5thtb4ktkq.jpg"
                  />
                  <div className="right-description">
                    <h3>Le poisson intéractif</h3>
                    <p>
                      Ce poisson sort librement de son sac lorsque vous le posez
                      dans l'eau. Vendu avec son aqurium et ses accessoires.
                    </p>
                    <span>5 ans et +</span>
                    <a
                      href="https://amzn.to/3FFRsIO"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>35,24 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Cochon d'inde LITTLE LIVE PETS"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1697800356/MyTrendIdeas/cochon-inde-llp-uffjhawgcjtomey661sg.jpg"
                  />
                  <div className="right-description">
                    <h3>Le cochon d'inde intéractif</h3>
                    <p>
                      Les enfants peuvent caresser, nourrir et brosser ce cochon
                      d'inde avant de le remettre dans son clapier avec ses
                      bébés.
                    </p>
                    <span>4 ans et +</span>
                    <a
                      href="https://amzn.to/3Shi4aL"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>64,90 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="L'oiseau LITTLE LIVE PETS"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1697800442/MyTrendIdeas/oiseau-llp-jnd0dp43kkehv5miallc.jpg"
                  />
                  <div className="right-description">
                    <h3>L'OISEAU INTÉRACTIF LITTLE LIVE PETS</h3>
                    <p>
                      Cet oiseau émet plus de 20 sons et réactions. Plus vous
                      prenez soin d'eux, plus ils feront de sons.
                    </p>
                    <span>5 ans et +</span>
                    <a
                      href="https://amzn.to/3MpIPpI"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>44,47 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Cameleon LITTLE LIVE PETS"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1697800491/MyTrendIdeas/cameleon-llp-ab9nskeomnrts5zmqurz.jpg"
                  />
                  <div className="right-description">
                    <h3>Sunny le caméléon lumineux</h3>
                    <p>
                      Sunny le caméléon s'illumine et change de couleur selon
                      son humeur. Vous pouvez également choisir Nova, sa
                      compagne Caméléon.
                    </p>
                    <span>5 ans et +</span>
                    <a
                      href="https://amzn.to/3Sm6RWh"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>25,56 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
              </div>
              <h2>LA FOLIE FURBY D'HASBRO</h2>
              <div className="ideas-container">
                <div className="idea-container">
                  <img
                    alt="Furby Hasbro Violet"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1697801542/MyTrendIdeas/furby-violet-ykl7htfa2jimjkenpmgq.jpg"
                  />
                  <div className="right-description">
                    <h3>Le Furby Violet</h3>
                    <p>
                      Ce mini compagnon poilu et coloré parle, chante, danse,
                      fait des blagues, s'illumine et réagit lorsque les enfants
                      disent : "On danse", "Imitation", "Dis-moi mon avenir",
                      "Relax" et "Lumières".
                    </p>
                    <span>6 ans et +</span>
                    <a
                      href="https://amzn.to/49fhccy"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>49,99 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="nerf blaster Fortnite"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698547029/MyTrendIdeas/nerf-blaster-fortnite-b1ioci1ta0h6fluq0orn.jpg"
                  />
                  <div className="right-description">
                    <h3>Le blaster NERF Fortnite</h3>
                    <p>
                      Ce blaster Nerf fortnite ar-l est inspiré du blaster
                      employé dans le jeu vidéo populaire Fortnite Battle
                      Royale, recréant son apparence et ses couleurs. Cadeau
                      parfait pour les minis gamers.
                    </p>
                    <span>8 ans et +</span>
                    <a
                      href="https://amzn.to/45W26ps"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>46,95 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Poney FURREAL"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1697802248/MyTrendIdeas/poney-furreal-il3j6kts3chsjqgpznj3.jpg"
                  />
                  <div className="right-description">
                    <h3>Le Poney FURREAL</h3>
                    <p>
                      Cet oiseau émet plus de 20 sons et réactions. Plus vous
                      prenez soin d'eux, plus ils feront de sons.
                    </p>
                    <span>4 ans et +</span>
                    <a
                      href="https://amzn.to/3Qhyq0n"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>67,70 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Zèbre arc en ciel FURREAL"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1697802301/MyTrendIdeas/zebre-furreal-a3s2gt7nveowbxze16bo.jpg"
                  />
                  <div className="right-description">
                    <h3>Le Zèbre arc-en-ciel FURREAL</h3>
                    <p>
                      Zénya est un zèbre arc-en-ciel qui cligne des yeux, bouge
                      ses oreilles, sa tête et son cou et emet plus de 60 sons
                      différents. Cet animal de compagnie coloré pourra être
                      coiffé grâce aux 20 accessoires inclus.
                    </p>
                    <span>4 ans et +</span>
                    <a
                      href="https://amzn.to/3FKSzqB"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>88,43 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
              </div>
              <h2>LES INDEMODABLES LEGO</h2>
              <div className="ideas-container">
                <div className="idea-container">
                  <img
                    alt="LEGO Bateau Vaiana"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1697802746/MyTrendIdeas/lego-vaiana-lzftokte534wetijioeq.jpg"
                  />
                  <div className="right-description">
                    <h3>Le bateau de VAIANA de Dysney</h3>
                    <p>
                      Les petits fans du dessin animé Vaiana vont adorer ce jeu
                      à construire représentant le bateau emblématique.
                    </p>
                    <span>6 ans et +</span>
                    <a
                      href="https://amzn.to/45UQHqa"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>29,99 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Lego GROOT"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1697802937/MyTrendIdeas/lego-groot-hx3zg1utpu6gwghdd8u6.jpg"
                  />
                  <div className="right-description">
                    <h3>Groot</h3>
                    <p>
                      Les enfants fans de l'univers Marvel vont adorer
                      reconstituer cette réplique de bébé Groot, le gardien de
                      la galaxie.
                    </p>
                    <span>10 ans et +</span>
                    <a
                      href="https://amzn.to/45U7YiY"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>39,90 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="LEGO BOWSER"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1697803113/MyTrendIdeas/lego-bowser-jefrft1zylbiw8bskiiq.jpg"
                  />
                  <div className="right-description">
                    <h3>BOWSER</h3>
                    <p>
                      Ce jouet est idéal pour les grands enfants et les
                      collectionneurs. Ce kit de construction de Bowser, le boss
                      final de Super Mario a des bras, des mains, des jambes et
                      une queue articulés pour créer différentes poses, a une
                      tête rotative, et la bouche s'ouvre en appuyant sur un
                      bouton sous la coquille.
                    </p>
                    <span>18 ans et +</span>

                    <a
                      href="https://amzn.to/46Rerg9"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>199,00 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="lego ninjago"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1697803268/MyTrendIdeas/lego-ninjago-il693annfaqkhikhbqir.jpg"
                  />
                  <div className="right-description">
                    <h3>L'Ultra Dragon d'Or de NINJAGO</h3>
                    <p>
                      Ce jeu LEGO NINJAGO comprend le plus grand dragon NINJAGO
                      à ce jour, tout en or, avec 4 têtes, jambes et queue
                      entièrement articulées et une paire d'ailes qui se
                      déplient en 3 lames. Il y a également 9 minifigurines : un
                      Lloyd Oni doré exclusif, un Cole doré, un Zane doré, un
                      Kai doré et un Jay doré, tous équipés d'armes élémentaires
                      dorées, le Roi de Cristal, 2 Brutes de pierre venge et un
                      Guerrier de pierre venge avec des armes lumineuses, des
                      lances et des épées jouets.
                    </p>
                    <span>9 ans et +</span>
                    <a
                      href="https://amzn.to/45SthSe"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>149,99 € | Acheter sur AMAZON</button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ChristmasForKids;
