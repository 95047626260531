import React from "react";
/* import Title from "./Title";
 */ /* import Unicorn from "../assets/licorne (1).png";
 */ /* import Baseline from "./Baseline"; */

function Header() {
  return (
    <div className="header">
      <div className="wrapper">
        <a href="/">
          <img
            alt="logo"
            src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698288125/MyTrendIdeas/logo-mes-idees-tendances-xik86vbabf7lzvpwmdpo.png"
          ></img>
        </a>
      </div>
    </div>
  );
}

export default Header;
