import React from "react";

const HomemadeNewbornGifts = () => {
  return (
    <>
      <div className="wrapper">
        <div className="article-info">
          <div>
            <h1>15 idées de cadeaux de naissance fait-main</h1>
            <p>
              Ca y est. Votre fille / fils / petit-fils / petite-fille / neveu /
              nièce ... est né et vous êtes emerveillé devant ces petits yeux
              brillants, sa peau douce et ce gazouillis de bébé inimitable. Il
              faut maintenant lui trouver un cadeau à la hauteur de votre
              émotion et de l'amour inestimable que vous ressentez déjà.
              Pourquoi pas lui offrir un cadeau personnalisé et fait-main qu'il
              ou elle gardera toute sa vie.
            </p>
            <p className="theme-credit">Publié le 2 novembre 2023</p>
            <img
              alt="Cadeaux de naissance fait-main"
              src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698849099/MyTrendIdeas/theme-cadeau-naissance-fait-main-wnwmf4pce74mves7b3hs.jpg"
            ></img>
          </div>
        </div>
      </div>
      <div className="grey-back">
        <div className="wrapper">
          <div className="article-details">
            <div className="ideas">
              <div className="ideas-container">
                <div className="idea-container">
                  <img
                    alt="La veilleuse personnalisable"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698841825/MyTrendIdeas/veilleuse-personnalisable-utqiiniom8cjcrvsehtb.avif"
                  />
                  <div className="right-description">
                    <h3>La veilleuse personnalisable</h3>
                    <p>
                      Cette veilleuse personnalisée ornée de quelques animaux de
                      la jungle sera un compagnon idéal pour bébé. La luminosité
                      est réglable pour adapter l'ambiance de la chambre à la
                      phase d'endormissement de bébé.
                    </p>
                    <p>
                      L'artisan, Nigel de HillsideHandmadeArt, est basé en
                      Grande-Bretagne et livre sous 48 heures.
                    </p>
                    <a
                      href="https://www.etsy.com/fr/listing/1562865578/veilleuse-avec-prenom-personnalise?ga_order=most_relevant&ga_search_type=all&ga_view_type=gallery&ga_search_query=veilleuse+%C3%A9clairage&ref=sc_gallery-1-2&pro=1&frs=1&bes=1&referrer_page_guid=286f8b89-a0c7-4034-8d16-3ebb06a9320c&plkey=e04b53a8a4da483ac39fd0074bd15e52d9ee07c0%3A1562865578"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>51,45 € | Acheter sur ETSY</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Le mobile musical Ballerine"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698806930/MyTrendIdeas/mobile-musical-ballerine-xtdv8gozupboqzi3v6hl.avif"
                  />
                  <div className="right-description">
                    <h3>Le mobile musical Ballerine</h3>
                    <p>
                      Cette petite ballerine et les deux cygnes blancs tournent
                      au-dessus du lit de bébé en jouant l'air de la célèbre
                      berceuse de Brahms. Le mobile peut être personnalisé avec
                      le nom de l'enfant sur un nuage.
                    </p>
                    <p>
                      L'artisan est basé en France et livre sous 4 à 6 semaines.
                    </p>
                    <a
                      href="https://www.etsy.com/fr/listing/694389611/mobile-musical-bebe-fille-ballerine?ref=yr_purchases"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>72,00 € | Acheter sur ETSY</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Le mobile Dragon"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698808798/MyTrendIdeas/mobile-dragon-jjbgev5dd4zpqlv1xoqy.webp"
                  />
                  <div className="right-description">
                    <h3>Le mobile Dragon</h3>
                    <p>
                      Ce mobile dragon fera voyager bébé sur son dos vers des
                      contrées fantastiques, au milieu des nuages et des
                      étoiles.
                    </p>
                    <p>
                      L'artisan est basé en Ukraine et livre sous 2 à 4
                      semaines.
                    </p>
                    <a
                      href="https://www.etsy.com/fr/listing/1592231135/mobile-bebe-dragon-pour-lit-de-bebe?ref=search_srv-5&pro=1&frs=1&sts=1&plkey=a813aed2cd0cb8670e4b12a1edf8aca5b34a8751%3A1592231135"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>139,93 € | Acheter sur ETSY</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="La tour à empiler Tigre"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698837347/MyTrendIdeas/tour-empiler-tigre-jlj5vuten9xbbia0wmpd.webp"
                  />
                  <div className="right-description">
                    <h3>La tour à empiler Tigre</h3>
                    <p>
                      Lorsque cette tour est empilée dans le bon ordre, un petit
                      tigre apparaît pour le plaisir des tout-petits. Chaque
                      anneau peut être personnalisé : pourquoi pas graver le
                      prénom, la date, l'heure, le poids et la taille de
                      naissance de l'enfant.
                    </p>
                    <p>
                      L'artisan, Mallou, est basé en France et livre sous 2
                      semaines.
                    </p>
                    <a
                      href="https://www.etsy.com/fr/listing/1206657860/tour-a-empiler-tigre-personnalisee?ga_order=most_relevant&ga_search_type=all&ga_view_type=gallery&ga_search_query=activit%C3%A9+b%C3%A9b%C3%A9&ref=sr_gallery-1-42&bes=1&local_signal_search=1&referrer_page_guid=c6a8ec75-003e-4033-9bce-b32bcbac3f7e&organic_search_click=1"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>22,90 € | Acheter sur ETSY</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="La balançoire MONTESSORI"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698837809/MyTrendIdeas/balan%C3%A7oire-montessori-scsiol4fp1snozptphyz.webp"
                  />
                  <div className="right-description">
                    <h3>La balançoire MONTESSORI</h3>
                    <p>
                      Cette balançoire stimule directement le cerveau de
                      l'enfant, aide à développer le sens de l'équilibre, la
                      coordination motrice, améliore la concentration et apaise.
                      Cette balançoire fera le bonheur des petits (jusqu'à
                      25kg).
                    </p>
                    <p>
                      L'artisan est basé en Ukraine et livre sous 2 à 4
                      semaines.
                    </p>
                    <a
                      href="https://www.etsy.com/fr/listing/870126458/balancoire-bebe-babyschaukel?ga_order=most_relevant&ga_search_type=all&ga_view_type=gallery&ga_search_query=chaise+activit%C3%A9+b%C3%A9b%C3%A9&ref=sr_gallery-1-10&pro=1&sts=1&referrer_page_guid=8e67c2c1-0179-4d51-9761-fb5c91801145&organic_search_click=1"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>64,00 € | Acheter sur ETSY</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Le coffre à jouet personnalisé"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698842271/MyTrendIdeas/coffre-jouet-piy4oqvqmo2dhgbh8phd.avif"
                  />
                  <div className="right-description">
                    <h3>Le coffre à jouet personnalisé</h3>
                    <p>
                      Ce magnifique coffre à jouets, fabriqué à la main,
                      renfermera tous les trésors des petits. Les motifs sont
                      également fait-main par Emilia.
                    </p>
                    <p>
                      L'artisan, Emilia de EmiliaLotteAtelier, est basée en
                      Allemagne et livre sous 4 semaines.
                    </p>
                    <a
                      href="https://www.etsy.com/fr/listing/1104943685/holzbox-spielzeugkiste-holz?ga_order=most_relevant&ga_search_type=all&ga_view_type=gallery&ga_search_query=&ref=sc_gallery-1-1&referrer_page_guid=f736aa8af9b.e0039e36132b87280f37.00&plkey=94f25dffbb42969f24c4bd90d1a2f31d344299d3%3A1104943685"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>199,00 € | Acheter sur ETSY</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Le lapin en crochet LUCKY personnalisable"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698808451/MyTrendIdeas/lapin-crochet-lucky-q2tvpgalrhijpzwpsmcq.webp"
                  />
                  <div className="right-description">
                    <h3>Le lapin en crochet LUCKY personnalisable</h3>
                    <p>
                      Ce petit lapin réalisé en crochet est prêt à être caliné
                      par bébé. Le doudou peut être personnalisé avec le nom de
                      l'enfant brodé.
                    </p>
                    <p>
                      L'artisan, Ursula, est basée en France et livre sous 4
                      semaines.
                    </p>
                    <a
                      href="https://www.etsy.com/fr/listing/268031324/lapin-en-crochet-lucky-personnalise?click_key=eca3dc1291fdedad806e82d424108e17d6ff8dfb%3A268031324&click_sum=b40bddea&ref=shop_home_active_7&pro=1&sts=1"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>53,50 € | Acheter sur ETSY</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Le coffret Vaisselle pour bébé"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698841258/MyTrendIdeas/coffret-vaisselle-bebe-pzlioulngaopugjelcbv.avif"
                  />
                  <div className="right-description">
                    <h3>Le coffret Vaisselle en silicone pour bébé</h3>
                    <p>
                      Ce coffret contient un bol, une assiette, une cuillère et
                      une fourchette personnalisables, un bavoir et un gobelet
                      avec son couvercle. Les assiettes sont ventousées afin
                      d'éviter que bébé ne refasse la décoration des murs.
                    </p>
                    <p>
                      L'artisan, Brice de MonBonheurAtelier, est basée en France
                      et livre sous 2 semaines.
                    </p>
                    <a
                      href="https://www.etsy.com/fr/listing/1514643399/cadeau-coffret-naissance-set-repas-bebe?ga_order=most_relevant&ga_search_type=all&ga_view_type=gallery&ga_search_query=&ref=sc_gallery-1-3&bes=1&referrer_page_guid=f736aa8af9b.e0039e36132b87280f37.00&plkey=17d7e2690fd29648d771e4740ef9834b89faac4c%3A1514643399"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>A partir de 24,99 € | Acheter sur ETSY</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Le toboggan d'intérieur"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698836962/MyTrendIdeas/toboggan-interieur-bois-rfhqkfardua1acqhq4fr.webp"
                  />
                  <div className="right-description">
                    <h3>Le toboggan en bois d'intérieur</h3>
                    <p>
                      Ce toboggan d'intérieur en bois est parfait pour les
                      premières glissades. Escalader les petites marches et
                      glisser les aidera à développer leur équilibre et leurs
                      capacités motrices.
                    </p>
                    <p>
                      L'artisan est basé en France et livre sous 2 semaines.
                    </p>
                    <a
                      href="https://www.etsy.com/fr/listing/787300183/toboggan-interieur-en-bois-pour-tout?ga_order=most_relevant&ga_search_type=all&ga_view_type=gallery&ga_search_query=activit%C3%A9+b%C3%A9b%C3%A9&ref=sc_gallery-1-14&cns=1&sts=1&referrer_page_guid=c6a8ec75-003e-4033-9bce-b32bcbac3f7e&plkey=b6ac94de7ba8d7b4cec112d189347bdc6a7731f5%3A787300183"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>A partir de 180,00 € | Acheter sur ETSY</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Lot de 4 jouets de bain en silicone"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698839153/MyTrendIdeas/jouets-bain-silicone-zjbis8gpqqnrdn3k0yg2.avif"
                  />
                  <div className="right-description">
                    <h3>Le lot de 4 jouets de bain en silicone</h3>
                    <p>
                      Ces jouets sont conçus pour être saisis par des petites
                      mains et être machouillés par des petites dents de bébé.
                      Ils vont dans l'eau et seront idéal pour divertir bébé à
                      l'heure du bain. En option, vous pouvez les personnaliser
                      en y ajoutant le prénom de bébé ou un petit mot doux.
                    </p>
                    <p>
                      L'artisan, Olga, est basée aux Pays-Bas et livre sous 2
                      semaines.
                    </p>
                    <a
                      href="https://www.etsy.com/fr/listing/1481123988/jborn-lot-de-4-jouets-de-bain-en?ga_order=most_relevant&ga_search_type=all&ga_view_type=gallery&ref=sc_gallery-1-10&referrer_page_guid=f736aa8af9b.e0039e36132b87280f37.00&plkey=919eb302ebe022e46bfc400fa328809a76243f88%3A1481123988&variation0=3547070292"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>12,95 € | Acheter sur ETSY</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Le cube de motricité en bois personnalisable"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698838046/MyTrendIdeas/cube-motricite-personnalisable-gh7wuhodgaarwssxe60n.webp"
                  />
                  <div className="right-description">
                    <h3>Le cube de motricité en bois personnalisable</h3>
                    <p>
                      Ce cube de motricité en bois est un excellent premier
                      jouet avec de nombreux défis pour un enfant : des perles
                      de couleurs à glisser, des formes à gloisser ou insérer,
                      un xylophone et une petite roue. En option, vous pouvez
                      personnaliser le jouet avec le nom de l'enfant.
                    </p>
                    <p>
                      L'artisan, Célina, est basée en Allemagne et livre sous 2
                      semaines.
                    </p>
                    <a
                      href="https://www.etsy.com/fr/listing/1462549545/cube-de-motricite-jouet-montessori?click_key=3adc3660f55fae4891e16787efcd4068df3bf86a%3A1462549545&click_sum=04b2840b&ref=rv_more-1-12&bes=1"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>A partir de 42,90 € | Acheter sur ETSY</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Les chaussures rampantes"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698817471/MyTrendIdeas/chaussons-bebe-xsrcqvn0bx5nudnoutnq.webp"
                  />
                  <div className="right-description">
                    <h3>Chaussures rampantes Boho ours</h3>
                    <p>
                      Ces petites chaussures compléteront la garde-robe de bébé.
                      En option, vous pouvez les personnaliser avec le nom de
                      l'enfant brodé.
                    </p>
                    <p>
                      L'artisan, Sabrina, est basée en Autriche et livre sous 2
                      semaines.
                    </p>
                    <a
                      href="https://www.etsy.com/fr/listing/1325790490/chaussures-rampantes-avec-nom-indien?ga_order=most_relevant&ga_search_type=all&ga_view_type=gallery&ga_search_query=chaussures+b%C3%A9b%C3%A9&ref=sc_gallery-1-4&bes=1&referrer_page_guid=f7359ab7609.89996c752baa1d385721.00&plkey=99fa8de2c19f4cb28cd280ab85781469940c51fe%3A1325790490"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>A partir de 31,00 € | Acheter sur ETSY</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Le poncho de bain personnalisé"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698839393/MyTrendIdeas/poncho-bain-personnalis%C3%A9-csfn7ueym1gy2hwuhly1.avif"
                  />
                  <div className="right-description">
                    <h3>Le poncho de bain personnalisé</h3>
                    <p>
                      Enveloppez bébé dans ce nuage en éponge douillet avec des
                      oreilles. Ce ponch est vendu en rose pâle, nougat,
                      caramel, gris tempête, vert cendré ou bleu jean. Il est
                      personnalisable avec le prénom de l'enfant.
                    </p>
                    <p>
                      L'artisan, Effis, est basée en Allemagne et livre sous 2
                      semaines.
                    </p>
                    <a
                      href="https://www.etsy.com/fr/listing/1333912829/poncho-de-bain-personnalise-enfantbebe?ga_order=most_relevant&ga_search_type=all&ga_view_type=gallery&ga_search_query=&ref=sc_gallery-1-4&pro=1&sts=1&referrer_page_guid=f736aa8af9b.e0039e36132b87280f37.00&plkey=22f716a376187ba9803f68106c7aca16d3119545%3A1333912829"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>A partir de 28,90 € | Acheter sur ETSY</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="La bascule en bois et son matelas"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698836014/MyTrendIdeas/lit-balancoire-vpcerghdmn8lbbipaujq.avif"
                  />
                  <div className="right-description">
                    <h3>La bascule en bois et son oreiller</h3>
                    <p>
                      Ce lit à bascule en bois et son oreiller vous permettront
                      de bercer bébé dans un cocon douillet. Lorsqu'il sera plus
                      grand, il suffit de retourner l'arc pour qu'il se
                      transforme en arc d'escalade Montessori.
                    </p>
                    <p>
                      L'artisan, Marek, est basée en Pologne et livre sous 2
                      semaines.
                    </p>
                    <a
                      href="https://www.etsy.com/fr/listing/1098622745/arc-descalade-montessori-balancing?ga_order=highest_reviews&ga_search_type=all&ga_view_type=gallery&ga_search_query=chaise+activit%C3%A9+b%C3%A9b%C3%A9&ref=sc_gallery-1-13&pro=1&frs=1&sts=1&referrer_page_guid=0885ffa2-e466-480a-acfb-e83e7fa6570b&plkey=f0fa6a946eb321a2f58015da30b0971f130f06c4%3A1098622745&variation0=2284083525"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>A partir de 184,50 € | Acheter sur ETSY</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Le fauteuil club déhoussable"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698835058/MyTrendIdeas/fauteuil-dehoussable-ours-bnhni7ovsl8rsweq1huk.avif"
                  />
                  <div className="right-description">
                    <h3>Le fauteuil club déhoussable</h3>
                    <p>
                      Ce fauteuil pour enfant en mousse est entièrement
                      déhoussable et lavable en machine à 30°. Le dossier de la
                      housse est en forme d'ours polaire endormi et le prénom de
                      l'enfant peut être brodé dans la couleur souhaitée.
                    </p>
                    <p>
                      L'artisan, Tessa, est basée en France et livre sous 4
                      semaines.
                    </p>
                    <a
                      href="https://www.etsy.com/fr/listing/1295338693/fauteuil-club-enfant-personnalise?click_key=a5c7ce2407a33f7819b1eb2e61545165ba5b8a00%3A1295338693&click_sum=14e21c0f&ref=shop_home_recs_2"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>A partir de 59,00 € | Acheter sur ETSY</button>
                    </a>
                  </div>
                </div>
                <div className="idea-container">
                  <img
                    alt="Les bouteilles sensorielles Cercles et Paillettes biodégradables"
                    src="https://res.cloudinary.com/dug8eeke1/image/upload/v1698836526/MyTrendIdeas/bouteilles-sensoreilles-gz4np1iskx8a0ttbid5o.webp"
                  />
                  <div className="right-description">
                    <h3>
                      Les bouteilles sensorielles Cercles et Paillettes
                      biodégradables
                    </h3>
                    <p>
                      Les bouteilles sensorielles sont des activités simples qui
                      stimulent la vue, l'ouie et le toucher chez les petits
                      selon la méthode MONTESSORI. Ces bouteilles sensorielles
                      sont absolumment fascinantes tant elles brillent et
                      captent l'attention.
                    </p>
                    <p>
                      L'artisan, Manuela, est basée en Espagne et livre sous 2
                      semaines.
                    </p>
                    <a
                      href="https://www.etsy.com/fr/listing/1543621252/bouteille-sensorielle-cercles-et?ga_order=most_relevant&ga_search_type=all&ga_view_type=gallery&ga_search_query=activit%C3%A9+b%C3%A9b%C3%A9&ref=sr_gallery-1-6&bes=1&sts=1&referrer_page_guid=c6a8ec75-003e-4033-9bce-b32bcbac3f7e&organic_search_click=1"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <button>A partir de 6,75 € | Acheter sur ETSY</button>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HomemadeNewbornGifts;
